import "./index.scss";
import React, { useState } from "react";
import MobileHeader from "../../../components/ui/mobile/MobileHeader";
import HeaderIcon from "../../../components/ui/mobile/HeaderIcon";
import HeaderTitle from "../../../components/ui/mobile/HeaderTitle";
import { isMobile, switchMatch } from "../../../utils";
import router from "../../../router";
import { PAGE_ROUTES } from "../../../definitions";
import router5 from "../../../router";
import useOnLoad from "../../../hooks/useOnLoad";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "../../../store/actions";
import { useIsLogged } from "../../../services/api/LoggedContext";
import Footer from "../../../components/Footer";
import NewHeaderTitle from "../../../components/ui/mobile/NewHeaderTitle/index";
import Loading from "../../../components/ui/universal/Loading";
import SelectVariation from "../../../components/common/SelectVariation";

const MainWrapper = (props) => {
  const isLogged = useIsLogged();
  const [state, setState] = useState({
    headerContextOpened: false,
  });
  const dispatch = useDispatch();
  const { loaded } = useOnLoad();
  const { sections, activeSection } = useSelector((store) => ({
    city: store.common.city,
    sections: store.common.sections,
    activeSection: store.common.activeSection,
    tags: store.common.tags,
  }));

  const __getRouterState = () => {
    return router5.getState();
  };

  const __navigate = (name = undefined) => {
    let params = { ...__getRouterState().params };
    router5.navigate(name, params);
  };

  if (!loaded) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const sectionsMapping = () => {
    return Object.keys(sections).map((sectionKey) => {
      const section = sections[sectionKey];
      return (
        <div
          className="MainWrapper_item"
          key={`section_${section.id}`}
          onClick={() => {
            dispatch(commonActions.setActiveSection(section));
            setState({
              ...state,
              headerContextOpened: false,
            });
          }}>
          <SelectVariation
            name={section.title}
            price={0}
            checked={activeSection.id === section.id}
            onChangeHandler={() => {}}
          />
        </div>
      );
    });
  };

  const { params: routeParams, name: routeName } = router.getState();
  const { section: routeSection } = routeParams;

  const headerContext = <div className="w-100">{sectionsMapping()}</div>;

  const renderHeader = () => {
    return (
      <MobileHeader
        contextOpened={state.headerContextOpened}
        context={headerContext}
        contextCloseHandler={() => {
          setState({
            ...state,
            headerContextOpened: false,
          });
        }}
        left={
          !state.headerContextOpened ? (
            switchMatch(routeName, {
              point: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.MAIN);
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.MAIN]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.SEARCH);
                  }}>
                  <HeaderIcon typeIcon="search" />
                </div>
              ),
              [PAGE_ROUTES.MYORDERS]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.MENU);
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.SETTINGS]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.MENU);
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.NEWSETTINGS]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.MENU);
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.NOTIFICATIONS]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.MENU);
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.FORDEV]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.MENU);
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.AGREEMENT]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.MENU);
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.CODE]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.MENU);
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.MYADDRESS]: (
                <div
                  onClick={() => {
                    switch (routeSection) {
                      case "add":
                        router5.navigate(PAGE_ROUTES.MYADDRESS, {
                          section: "first",
                        });
                        break;
                      default:
                        router5.navigate(PAGE_ROUTES.MENU);
                        break;
                    }
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),

              [PAGE_ROUTES.SETTINGS]: (
                <div
                  onClick={() => {
                    switch (routeSection) {
                      case "security":
                        router5.navigate(PAGE_ROUTES.SETTINGS, {
                          section: "general",
                        });
                        break;
                      case "privacy":
                        router5.navigate(PAGE_ROUTES.SETTINGS, {
                          section: "general",
                        });
                        break;
                      default:
                        router5.navigate(PAGE_ROUTES.MENU);
                        break;
                    }
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),

              [PAGE_ROUTES.SPECIALMENU]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.MENU);
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.GENERAL_POINT_SETTINGS]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.SPECIALMENU, {
                      id: router.getState().params.id,
                    });
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.BALANCE]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.SPECIALMENU, {
                      id: router.getState().params.id,
                    });
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.PERSONAL]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.SPECIALMENU, {
                      id: router.getState().params.id,
                    });
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.SPECIALCATEGORIES]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.SPECIALMENU, {
                      id: router.getState().params.id,
                    });
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.SPECIALORDERS]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.SPECIALMENU, {
                      id: router.getState().params.id,
                    });
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.PRODUCTS]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.SPECIALMENU, {
                      id: router.getState().params.id,
                    });
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.OPTIONS]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.PRODUCTS, {
                      id: router.getState().params.id,
                    });
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.ADMIN_MENU]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.MENU);
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.CREATE_POINT]: (
                <div
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.ADMIN_MENU);
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              [PAGE_ROUTES.EDIT_POINT]: (
                <div
                  onClick={() => {
                    router5.navigate(PAGE_ROUTES.ADMIN_MENU);
                  }}>
                  <HeaderIcon typeIcon="back" />
                </div>
              ),
              default: <div />,
            })
          ) : (
            <div />
          )
        }
        right={
          !state.headerContextOpened ? (
            switchMatch(routeName, {
              default: <div />,
              [PAGE_ROUTES.MAIN]: (
                <div
                  onClick={() => {
                    __navigate(PAGE_ROUTES.CITIES);
                  }}>
                  <HeaderIcon typeIcon="location" />
                </div>
              ),
              point: <div />,
            })
          ) : (
            <div />
          )
        }>
        {isMobile() ? (
          renderHeaderContent()
        ) : (
          <div className="mobile-header-full_centered">
            <div>{renderHeaderContent()}</div>
          </div>
        )}
      </MobileHeader>
    );
  };

  const renderHeaderContent = () => {
    return (
      <div>
        {switchMatch(routeName, {
          default: <HeaderTitle align="center" />,
          myaddress: <HeaderTitle text="Добавить адрес" align="center" />,
          settings: <HeaderTitle text="Настройки" align="center" />,
          [PAGE_ROUTES.POINT]: <HeaderTitle text={activeSection.title} align="left" />,
          [PAGE_ROUTES.MENU]: <HeaderTitle text="Меню" align="center" />,
          [PAGE_ROUTES.MYORDERS]: <HeaderTitle text="Мои заказы" align="center" />,
          [PAGE_ROUTES.NOTIFICATIONS]: <HeaderTitle text="Уведомления" align="center" />,
          [PAGE_ROUTES.FORDEV]: <HeaderTitle text="Напишите нам" align="center" />,
          [PAGE_ROUTES.CODE]: <HeaderTitle text="Код" align="center" />,
          [PAGE_ROUTES.AGREEMENT]: <HeaderTitle text="Соглашение" align="center" />,
          [PAGE_ROUTES.SPECIALMENU]: <HeaderTitle text="Управление" align="center" />,
          [PAGE_ROUTES.GENERAL_POINT_SETTINGS]: <HeaderTitle text="Настройки" align="center" />,
          [PAGE_ROUTES.BALANCE]: <HeaderTitle text="Баланс" align="center" />,
          [PAGE_ROUTES.SPECIALCATEGORIES]: <HeaderTitle text="Категории" align="center" />,
          [PAGE_ROUTES.SPECIALORDERS]: <HeaderTitle text="Заказы" align="center" />,
          [PAGE_ROUTES.PERSONAL]: <HeaderTitle text="Персонал" align="center" />,

          [PAGE_ROUTES.PRODUCTS]: <HeaderTitle text="Товары/Услуги" align="center" />,
          [PAGE_ROUTES.OPTIONS]: <HeaderTitle text="Опции" align="center" />,
          [PAGE_ROUTES.ADMIN_MENU]: <HeaderTitle text="Управление" align="center" />,
          [PAGE_ROUTES.EDIT_POINT]: <HeaderTitle text="Редактирование" align="center" />,
          [PAGE_ROUTES.CREATE_POINT]: <HeaderTitle text="Настройки" align="center" />,
          [PAGE_ROUTES.MAIN]: (
            <NewHeaderTitle
              text={activeSection.title}
              align="center"
              after={
                !state.headerContextOpened ? (
                  <HeaderIcon typeIcon="min_down" min />
                ) : (
                  <HeaderIcon typeIcon="min_cancel" min />
                )
              }
              onClick={() => {
                setState({
                  ...state,
                  headerContextOpened: !state.headerContextOpened,
                });
              }}
            />
          ),
        })}
      </div>
    );
  };

  return (
    <>
      <div className="Page">
        {renderHeader()}

        <div className="Page-inner">
          <div>
            <div className="MainWrapper__content">{props.children}</div>
          </div>
        </div>
        {switchMatch(routeName, {
          [PAGE_ROUTES.POINT]: !isLogged || !isMobile() ? <Footer /> : <div />,
          default: <Footer />,
        })}
      </div>
    </>
  );
};

export default MainWrapper;

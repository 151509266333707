import "./index.scss";
import React, { useState } from "react";
import Spacer from "../../ui/universal/Spacer";
import ReactPhoneInput from "react-phone-input-2";
import PrimaryButton from "../../ui/universal/PrimaryButton";

const AuthPhone = (props) => {
  const { doAuth, phone, setPhone } = props;

  const handleOnChangePhone = (value, data) => {
    // console.log({
    //   phone: value,
    //   dialCode: data.dialCode,
    //   phoneWithoutCode: value.replace('+' + data.dialCode, '').replace(/[^\d;]/g, '')
    // });
  };

  return (
    <div>
      <div className="AuthPhone_description">
        <span>Введите ваш номер телефона для авторизации в приложении</span>
      </div>
      <Spacer size={10} />
      <ReactPhoneInput
        autoFormat
        disableDropdown={true}
        disableSearchIcon
        countryCodeEditable={false}
        enableSearch={false}
        onlyCountries={["ru"]}
        country="ru"
        placeholder="+7 915 123 45 67"
        preferredCountries={["ru"]}
        disableCountryCode={false}
        value={phone}
        masks={{ ru: "(...) ...-..-.." }}
        onChange={(value, data) => {
          setPhone(value);
          handleOnChangePhone(value, data);
        }}
      />

      <Spacer size={10} />

      <PrimaryButton text="Продолжить" mode="primary" btnType="submit" onClick={doAuth} />

      <Spacer size={4} direction="vertical" />
    </div>
  );
};

export default AuthPhone;

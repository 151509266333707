import "./index.scss";
import React, { useEffect } from "react";
import router from "../../../router";
import router5 from "../../../router";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import Spacer from "../../../components/ui/universal/Spacer";
import Input from "../../../components/ui/universal/Input";
import { switchMatch } from "../../../utils";
import { ReactComponent as LocSVG } from "../../../components/ui/mobile/HeaderIcon/assets/svg/location.svg";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "../../../store/actions/common-actions";
import Loading from "../../../components/ui/universal/Loading";
import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import Padding from "../../../components/ui/universal/Padding";
import cogoToast from "cogo-toast";
import { PAGE_ROUTES } from "../../../definitions";
import Cell from "../../../components/ui/mobile/Cell";

const REQUIRED_FIELD_ERROR = "Обязательное поле";

const MyAddressPage = () => {
  const { params: routeParams } = router.getState();
  const { section: routeSection } = routeParams;
  const dispatch = useDispatch();
  const { addresses } = useSelector((state) => ({
    addresses: state.common.addresses,
  }));

  useEffect(() => {
    dispatch(commonActions.getAddresses());
  }, []);

  if (addresses === null) {
    return <Loading />;
  }

  const initialFormikState = {
    name: "",
    phone: "",
    address: "",
    message: "",
  };

  const formikValidationSchema = Yup.object().shape({
    name: Yup.string().required(REQUIRED_FIELD_ERROR),

    phone: Yup.string().min(6, "Телефон должен быть не менее 6 символов").required(REQUIRED_FIELD_ERROR),

    message: Yup.string(),

    address: Yup.string().required(REQUIRED_FIELD_ERROR),
  });

  const handleRemoveAddress = ({ address_id }) => {
    const removeAddress = dispatch(commonActions.removeAddress({ address_id }));
    removeAddress.then(
      (data) => {
        if (data.data.status) {
          dispatch(commonActions.getAddresses()).then(() => {
            cogoToast.info("Удалено");
          });
        }
      },
      () => {
        cogoToast.error("Ошибка удаления");
      },
    );
  };

  const renderAddressesItem = ({ item }) => {
    const { name, phone, address, id: address_id } = item;
    return (
      <div>
        <Padding vertical={0} horizontal={2}>
          <div style={{ borderBottom: "1px solid #0000001f" }}>
            <Cell
              key={item}
              removable
              onRemove={() => {
                handleRemoveAddress({ address_id });
              }}>
              <div className="MyAddressPage_wrapper_item_box_name">{name}</div>
              <div className="MyAddressPage_wrapper_item_box_address">{address}</div>
              <div className="MyAddressPage_wrapper_item_box_comment">{phone}</div>
            </Cell>
          </div>
        </Padding>
      </div>
    );
  };

  const renderAddresses = () => {
    return (
      <div>
        {Object.keys(addresses.addresses).map((keyItem, key) => {
          const item = addresses.addresses[keyItem];
          return renderAddressesItem({ item, key });
        })}
      </div>
    );
  };

  const renderFirstPage = () => {
    return (
      <div>
        {!!Object.keys(addresses.addresses).length ? (
          <div>
            <div>
              <div className="MyAddressPage_buttonBox">
                <PrimaryButton
                  text="Добавить"
                  mode={"primary"}
                  onClick={() =>
                    router5.navigate("myaddress", {
                      section: "add",
                    })
                  }
                />
              </div>
            </div>
            {renderAddresses()}
          </div>
        ) : (
          <div className="MyAddressPage">
            <div className="MyAddressPage_wrapper">
              <div className="MyAddressPage_wrapper_content">
                <div className="MyAddressPage_wrapper_content_icon">
                  <LocSVG />
                </div>
                <div className="MyAddressPage_wrapper_content_title">
                  <span>Вы пока не добавили адрес доставки</span>
                </div>
                <div className="MyAddressPage_wrapper_content_description">
                  <span>Добавьте ваш адрес, чтобы сделать первый заказ</span>
                </div>
              </div>
            </div>
            <div></div>
            <div className="MyAddressPage_buttonBox">
              <PrimaryButton
                text="Добавить"
                mode={"primary"}
                onClick={() =>
                  router5.navigate("myaddress", {
                    section: "add",
                  })
                }
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={initialFormikState}
          validationSchema={formikValidationSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const addAddress = dispatch(commonActions.addAddress({ ...values }));
            addAddress.then(
              (response) => {
                if (response.status) {
                  cogoToast.success("Адрес успешно добавлен!");
                  setSubmitting(true);
                  dispatch(commonActions.getAddresses());
                  router5.navigate(PAGE_ROUTES.MYADDRESS, {
                    section: "first",
                  });
                }
              },
              (response) => {
                if (!response.status) {
                  if (response.hasOwnProperty("data")) {
                    let errors = {};
                    response.data.forEach(({ field }) => {
                      switch (field) {
                        case "phone":
                          errors[field] = "Номер телефона может состоять только из цифр";
                          break;
                        default:
                          if (values.hasOwnProperty(field)) {
                            errors[field] = "Ошибка значения";
                          }
                          break;
                      }
                    });
                    setErrors(errors);
                    setSubmitting(false);
                  }
                }
              },
            );
          }}
          render={({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Spacer size={15} />

              <Input
                name="name"
                mode="secondary"
                title="Имя"
                placeholder="Ваше имя"
                value={values.name}
                onChangeHandler={(e) => setFieldValue("name", e)}
                error={!!(errors.name && touched.name)}
                description={
                  errors.name &&
                  touched.name && <ErrorMessage name="name" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <Input
                name="phone"
                mode="secondary"
                title="Телефон"
                placeholder="8-900-123-55-77"
                value={values.phone}
                onChangeHandler={(e) => {
                  if (!e.match(/^\d{0,15}(\.\d{0,15}){0,1}$/)) {
                    return false;
                  }
                  setFieldValue("phone", e);
                }}
                error={!!(errors.phone && touched.phone)}
                description={
                  errors.phone &&
                  touched.phone && <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <Input
                name="address"
                mode="secondary"
                title="Адрес"
                placeholder="Город, улица, дом, подъезд"
                value={values.address}
                onChangeHandler={(e) => setFieldValue("address", e)}
                error={!!(errors.address && touched.address)}
                description={
                  errors.address &&
                  touched.address && <ErrorMessage name="address" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <Input
                name="message"
                mode="secondary"
                title="Комментарии"
                placeholder="Код домофона и тд"
                value={values.message}
                onChangeHandler={(e) => setFieldValue("message", e)}
                error={!!(errors.message && touched.message)}
                description={
                  errors.message &&
                  touched.message && <ErrorMessage name="message" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={10} />

              <PrimaryButton text="Добавить" mode={"primary"} btnType={"submit"} />
            </Form>
          )}
        />
      </div>
    );
  };

  return switchMatch(routeSection, {
    first: renderFirstPage,
    add: (
      <div>
        <div className="MyAddressPage">
          <Padding horizontal={2} vertical={2}>
            {renderForm()}
          </Padding>
        </div>
      </div>
    ),
  });
};

export default MyAddressPage;

import React from 'react';
import ReactDOM from 'react-dom';

import 'url-search-params-polyfill';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router5';

import store from './store/store';
import router from './router';

import App from './App';
import { APIAuthProvider } from './services/api/APIAuthProvider';

const spa = (
  <APIAuthProvider>
    <Provider store={store}>
      <RouterProvider router={router}>
        <React.StrictMode>
          <App
            store={store}
            router={router}
          />
        </React.StrictMode>
      </RouterProvider>
    </Provider>
  </APIAuthProvider>
);

router.start((err, state) => {
  ReactDOM.render(
    spa,
    document.getElementById('root')
  );
});

// https://create-react-app.dev/docs/using-https-in-development/

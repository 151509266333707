import "./index.scss";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { isEmptyObject } from "../../../utils";
import Input from "../../../components/ui/universal/Input";
import Card from "../../../components/ui/universal/Card";
import Spacer from "../../../components/ui/universal/Spacer";
import "../../../static/styles/input-phone.scss";
import { ISignInContent } from "./interface";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import cogoToast from "cogo-toast";
import { ReactComponent as BackSVG } from "../../../static/images/svg/new/left_chevron.svg";
import * as Modals from "../../../components/ui/universal/Modals";
import router from "../../../router";
import { PAGE_ROUTES } from "../../../definitions";
import { useApiRequest } from "../../../services/api/APIProvider";
import ModalError from "../../../components/common/ModalError";

const REQUIRED_FIELD_ERROR = "Обязательное поле";

const RegContent: React.FC<ISignInContent> = (props: any) => {
  const dispatch = useDispatch();
  const apiRequest = useApiRequest();
  const [error, setError] = useState<any>();
  const [selectedSexUser, setSelectedSexUser] = useState(0);
  const InitialFormikState = {
    full_name: "",
    sex: selectedSexUser,
  };
  const formikValidationSchema = Yup.object().shape({
    full_name: Yup.string().required(REQUIRED_FIELD_ERROR),
  });
  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={InitialFormikState}
          validationSchema={formikValidationSchema}
          onSubmit={(values) => {
            apiRequest("auth.extendAccount", {
              session_id: props.session,
              full_name: values.full_name,
              sex: selectedSexUser,
            })
              .then(({ response }: any) => {
                if (response) {
                  cogoToast.success("Регистрация успешна!");
                  Modals.clear();
                  router.navigate(PAGE_ROUTES.MAIN);
                }
                console.log(response);
              })
              .catch((r: any) => setError(r.error));
          }}
          render={({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Input
                title="Ваше имя"
                name="full_name"
                mode="secondary"
                placeholder="Василий"
                value={values.full_name}
                onChangeHandler={(e) => setFieldValue("full_name", e)}
                error={!!(errors.full_name && touched.full_name)}
                description={
                  errors.full_name &&
                  touched.full_name && <ErrorMessage name="full_name" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={13} />

              {error && <ModalError error={error!.error_message} modalClose={() => setError(undefined)} />}
              <div>
                <div className="Registration_box_description">
                  <span>Ваш пол</span>
                </div>
                <div className="Registration_box">
                  <div
                    className={selectedSexUser === 1 ? "Registration_box_item active-item" : "Registration_box_item"}
                    onClick={() => {
                      setSelectedSexUser(1);
                    }}>
                    <span>Мужской</span>
                  </div>
                  <div
                    className={selectedSexUser === 2 ? "Registration_box_item active-item" : "Registration_box_item"}
                    onClick={() => {
                      setSelectedSexUser(2);
                    }}>
                    <span>Женский</span>
                  </div>
                  <div
                    className={selectedSexUser === 0 ? "Registration_box_item active-item" : "Registration_box_item"}
                    onClick={() => {
                      setSelectedSexUser(0);
                    }}>
                    <span>Не указан</span>
                  </div>
                </div>
              </div>
              <Spacer size={13} />

              <div>
                <PrimaryButton text="Зарегистрироваться" mode="primary" btnType="submit" />

                <Spacer size={4} direction="vertical" />

                {!isEmptyObject(touched) && [
                  <PrimaryButton text="Сбросить" mode="red" btnType="reset" />,

                  <Spacer size={4} direction="vertical" />,
                ]}
              </div>
            </Form>
          )}
        />
      </div>
    );
  };

  const renderMainBlock = () => {
    return (
      <div className="Registration">
        <Card verticalPadding={3} horizontalPadding={3}>
          <div className="relative">
            <div className="Registration_header">
              <div
                className="Registration_header_left"
                onClick={() => {
                  Modals.clear().then(() => {
                    Modals.open("authorization", {}, {}, {});
                  });
                }}>
                <BackSVG />
              </div>
              <div className="Registration_header_center">
                <span>Регистрация</span>
              </div>
              <div className="Registration_header_right" />
            </div>

            <Spacer size={10} />
            <Spacer direction="vertical" size={4} />

            {renderForm()}

            <Spacer direction="vertical" size={4} />
          </div>
        </Card>
      </div>
    );
  };

  return renderMainBlock();
};

export default RegContent;

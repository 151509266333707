import { TYPES } from "../../actions";
import store from "../../../store/store";

export const loadingActions = {
  setLoading: (key) => (dispatch, getStore) => {
    dispatch({
      type: TYPES.LOADING.SET,
      payload: key,
    });
  },

  getLoading: (key) => {
    return store.getState().loading.isLoading.has(key);
  },

  removeLoading: (key) => (dispatch, getStore) => {
    dispatch({
      type: TYPES.LOADING.REMOVE,
      payload: key,
    });
  },

  clearLoading: (key) => (dispatch, getStore) => {
    dispatch({
      type: TYPES.LOADING.CLEAR,
      payload: key,
    });
  },
};

import "./index.scss";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/ui/universal/Input";
import Card from "../../../components/ui/universal/Card";
import { ReactComponent as SearchSVG } from "../../../components/ui/mobile/HeaderIcon/assets/svg/search.svg";
import router from "../../../router";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { commonActions } from "../../../store/actions";
import Loading from "../../../components/ui/universal/Loading";

const SigninContent = (props: any) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  const { activeCategory }: any = useSelector((state: any) => ({
    activeCategory: state.common.activePointCategoryId,
  }));

  const setActiveCategory = (val: number | null) => {
    dispatch(commonActions.setActivePointCategoryId(val));
  };

  const { params: routeParams } = router.getState();
  const { id: pointId } = routeParams;

  const { categories } = useSelector((store: any) => ({
    categories: store.common.categories,
    loadingStatus: store.common.loadingStatus,
  }));

  const sortable = (a: any, b: any) => {
    const categoryA = categories[pointId][a];
    const categoryB = categories[pointId][b];
    if (categoryA.priority > categoryB.priority) {
      return -1;
    } else {
      return 1;
    }
  };

  if (!Object.keys(categories).length) {
    return <Loading />;
  }

  const renderMainBlock = () => {
    return (
      <div className="PointCategories">
        <Card verticalPadding={3} horizontalPadding={3}>
          <div>
            <div className="PointCategories_heading">
              <span>Категории</span>
            </div>
            <div className="PointCategories_search">
              <Input
                icon={<SearchSVG />}
                mode="secondary"
                placeholder={"поиск"}
                value={value}
                onChangeHandler={setValue}
              />
            </div>
            {Object.keys(categories[pointId])
              .sort(sortable)
              .map((_category) => {
                const category = categories[pointId][_category];
                return (
                  <div
                    key={uuidv4()}
                    className={classNames("PointCategories_item", {
                      active: category.id === activeCategory,
                    })}
                    onClick={() => {
                      setActiveCategory(category.id);
                      props.close();
                    }}>
                    <span>{category.title}</span>
                  </div>
                );
              })}
          </div>
        </Card>
      </div>
    );
  };

  return renderMainBlock();
};

export default SigninContent;

import "./index.scss";
import React, { useCallback, useState } from "react";
import Card from "../../../components/ui/universal/Card";
import Spacer from "../../../components/ui/universal/Spacer";
import { useApiRequest } from "../../../services/api/APIProvider";
import { useAuthSetAccessToken, useAuthClearAccessToken } from "../../../services/api/AuthContext";
import PinCode from "../../../components/common/PinCode";
import AuthPhone from "../../../components/common/AuthPhone";
import ModalError from "../../../components/common/ModalError";
import { ReactComponent as BackSVG } from "../../../static/images/svg/new/left_chevron.svg";

export const SigninContent: React.FC = () => {
  const [phone, setPhone] = useState<any>("");
  const [paramExists, setParamExists] = useState<any>("");
  const [codeSession, setCodeSession] = useState<any>("");
  const [error, setError] = useState<any>();
  const handleOnChangePhone = (value: any, data: any) => {
    // console.log({
    //   phone: value,
    //   dialCode: data.dialCode,
    //   phoneWithoutCode: value.replace('+' + data.dialCode, '').replace(/[^\d;]/g, '')
    // });
  };

  console.log(3222, codeSession);
  const apiRequest = useApiRequest();
  const setAccessToken = useAuthSetAccessToken();
  const doAuth = useCallback(() => {
    apiRequest(
      "auth.sendCode",
      {
        phone,
      },
      { force: true },
    )
      .then(({ response }: any) => {
        setParamExists(response.exists);
        setCodeSession(response.session_id);
      })
      .catch((r: any) => setError(r.error));
  }, [phone]);

  /** logout example
     const clearAccessToken = useAuthClearAccessToken();
     apiRequest('auth.logout', {
  }).then(() => {
    clearAccessToken();
    //widow.reload();
  });
     */

  return (
    <div className="SingIn">
      <Card verticalPadding={3} horizontalPadding={3}>
        <div className="relative">
          <div className="SingIn_header">
            <div className="SingIn_header_left" onClick={() => setCodeSession("")}>
              {codeSession && <BackSVG />}
            </div>
            <div className="SingIn_header_center">
              {!codeSession.length ? <span>Вход</span> : <span>{!paramExists ? "Придумайте код" : "Введите код"}</span>}
            </div>
            <div className="SingIn_header_right" />
          </div>
          <Spacer size={5} />
          {!codeSession && (
            <div>
              <AuthPhone doAuth={doAuth} phone={phone} setPhone={setPhone} />
            </div>
          )}

          {error && <ModalError error={error!.error_message} modalClose={() => setError(undefined)} />}
          {codeSession && (
            <div>
              <PinCode codeSession={codeSession} exists={paramExists} />
            </div>
          )}

          <Spacer direction="vertical" size={4} />
        </div>
      </Card>
    </div>
  );
};

export default SigninContent;

import "./index.scss";
import React from "react";

const MoreDetailsItem = (props) => {
  const { icon, title, description } = props;

  return (
    <div className="MoreDetailsItem_contacts">
      <div className="MoreDetailsItem_contacts_details">
        <div className="MoreDetailsItem_contacts_details_wrapper">
          <div className="MoreDetailsItem_contacts_details_wrapper_boxSvg">{icon}</div>
        </div>

        <div className="MoreDetailsItem_contacts_details_box">
          <div className="MoreDetailsItem_contacts_details_box_info">
            <span>{title}</span>
          </div>
          <div className="MoreDetailsItem_contacts_details_box_additionally">
            <span>{description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreDetailsItem;

MoreDetailsItem.defaultProps = {
  title: "имя",
  description: "описание",
};

import "./index.scss";
import React, { useState } from "react";
import Switch from "../../../components/ui/universal/Switch";

const Notifications = () => {
  const [switchOn, setSwitchOn] = useState(" ");

  return (
    <div className="Notifications">
      <div className="Notifications_fake" />
      <div className="Notifications_item">
        <span>Получать SMS-уведомления</span>
        <div className="Notifications_item_box">
          <Switch on={switchOn} onChange={() => setSwitchOn(!switchOn)} />
        </div>
      </div>
      <div className="Notifications_item">
        <span>Получать Push-уведомления</span>
        <div className="Notifications_item_box">
          <Switch on={switchOn} onChange={() => setSwitchOn(!switchOn)} />
        </div>
      </div>
      <div className="Notifications_item">
        <span>Получать email-рассылки</span>
        <div className="Notifications_item_box">
          <Switch on={switchOn} onChange={() => setSwitchOn(!switchOn)} />
        </div>
      </div>
    </div>
  );
};

export default Notifications;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DONESIDO_LOCAL_STORAGE_PREFIX } from '../../config';

export const AuthContext = React.createContext({});
export const useAuthContext = () => useContext(AuthContext);

const AUTH_ACCESS_TOKEN_KEY = `${DONESIDO_LOCAL_STORAGE_PREFIX}access_token`;

const getLSToken = () => JSON.parse(localStorage.getItem(AUTH_ACCESS_TOKEN_KEY));
const setLSToken = (tokenData) => localStorage.setItem(AUTH_ACCESS_TOKEN_KEY, JSON.stringify(tokenData));
const clearLSToken = () => localStorage.removeItem(AUTH_ACCESS_TOKEN_KEY);
const initialAccessToken = getLSToken();

export const useAuthAccessToken = () => useAuthContext().accessToken?.access_token;
export const useAuthUserId = () => useAuthContext().accessToken?.user_id;
export const useAuthSetAccessToken = () => useAuthContext().setAccessToken;
export const useAuthClearAccessToken = () => useAuthContext().clearAccessToken;

export const AuthContextProvider = ({children}) => {
    const [accessToken, setAccessTokenState] = useState(initialAccessToken);
    useEffect(() => {
        const localToken = getLSToken();
        if (localToken) {
            setAccessTokenState(localToken);
        }
    }, []);

    const setAccessToken = useCallback((tokenData) => {
        setLSToken(tokenData);
        setAccessTokenState(tokenData);
    }, []);

    const clearAccessToken = useCallback(() => {
        clearLSToken();
        setAccessTokenState('');
    }, []);

    const params = {
        setAccessToken,
        clearAccessToken,
        accessToken,
    };
    return (
      <AuthContext.Provider value={params}>
          {children}
      </AuthContext.Provider>
    );
};

import fetch from "isomorphic-unfetch";
import { useAuthAccessToken } from "./api/AuthContext";
// import { APP_ENV } from '../app-env';
//
// let _API_DOMAIN;
// switch (APP_ENV.REACT_APP_TYPE) {
//   case "STAGE":
//     _API_DOMAIN = APP_ENV.REACT_APP_STAGE_MAIN_API;
//     break;
//   default:
//     _API_DOMAIN = APP_ENV.REACT_APP_MAIN_API;
//     break;
// }

export const API_VERSION = "1.1";
export const API_DOMAIN = "https://donesido.oue.app";
export const API_ENTRY = `${API_DOMAIN}/api/${API_VERSION}`;

export const apiResponseTreatment = (response: any) => {
  return new Promise((resolve, reject) => {
    if (response.errors.length > 0) {
      reject(response.errors);
    } else {
      resolve(response.data);
    }
  });
};

const AuthCheck = (): any => {
  return useAuthAccessToken();
};

export async function invoke(method: string, name: string, params: any, options: any = {}) {
  return new Promise(async (resolve, reject) => {
    const paramsArr = [];

    for (const key in params) {
      paramsArr.push(`${key}=${encodeURIComponent(params[key])}`);
    }

    let headers = {
      "Content-Type": "application/json",
    };

    let token = AuthCheck;
    if (token !== undefined) {
      // @ts-ignore
      headers["authorization"] = `Bearer ${token.access_token}`;
    }

    const init: any = {
      method,
      headers,
    };

    const apiEntry = options.apiEntry || API_ENTRY;

    let url = `${apiEntry}/${name}`;

    if (method === "GET") {
      url += `?${paramsArr.join("&")}`;
    } else {
      init.body = JSON.stringify(params);
    }

    fetch(url, init).then(async (resp) => {
      if (resp.status === 403) {
        // logout
        window.location.href = "/";
        return;
      }

      // if (resp.status === 401) {
      //   const nt = await refreshToken(token);
      //   window.location.reload();
      //   return;
      // }

      resp.json().then((data) => {
        if (resp.status === 200) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  });
}

export function get(name: string, params = {}) {
  return invoke("GET", name, params);
}

export function post(name: string, params = {}) {
  return invoke("POST", name, params);
}

export function put(name: string, params = {}) {
  return invoke("PUT", name, params);
}

export function patch(name: string, params = {}) {
  return invoke("PATCH", name, params);
}

export function del(name: string, params = {}) {
  return invoke("DELETE", name, params);
}

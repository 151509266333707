import "./index.scss";

import React from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Input from "../../../components/ui/universal/Input";
import Card from "../../../components/ui/universal/Card";
import Spacer from "../../../components/ui/universal/Spacer";
import "../../../static/styles/input-phone.scss";
import { ISignInContent } from "./interface";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import * as Modals from "../../../components/ui/universal/Modals";
// @ts-ignore
import { commonActions } from "../../../store/actions/common-actions";
import cogoToast from "cogo-toast";
import { ReactComponent as CloseSVG } from "../../../static/images/svg/new/close.svg";

const REQUIRED_FIELD_ERROR: any = "Обязательное поле";

const RegistrationContent: React.FC<ISignInContent> = (props: any) => {
  const dispatch = useDispatch();
  const initialFormikState = {
    name: "",
    phone: "",
    address: "",
    message: "",
  };
  const formikValidationSchema = Yup.object().shape({
    name: Yup.string().required(REQUIRED_FIELD_ERROR),

    phone: Yup.string().min(6, "Телефон должен быть не менее 6 символов").required(REQUIRED_FIELD_ERROR),

    message: Yup.string(),

    address: Yup.string().required(REQUIRED_FIELD_ERROR),
  });

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={initialFormikState}
          validationSchema={formikValidationSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const addAddress: any = dispatch(commonActions.addAddress({ ...values }));
            addAddress.then(
              (response: any) => {
                if (response.status) {
                  cogoToast.success("Адрес успешно добавлен!");
                  setSubmitting(true);
                  Modals.close();
                }
              },
              (response: any) => {
                if (!response.status) {
                  if (response.hasOwnProperty("data")) {
                    let errors: any = {};
                    response.data.forEach(({ field }: any) => {
                      switch (field) {
                        case "phone":
                          errors[field] = "Номер телефона может состоять только из цифр";
                          break;
                        default:
                          if (values.hasOwnProperty(field)) {
                            errors[field] = "Ошибка значения";
                          }
                          break;
                      }
                    });
                    setErrors(errors);
                    setSubmitting(false);
                  }
                }
              },
            );
          }}
          render={({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Spacer size={15} />

              <Input
                name="name"
                mode="secondary"
                title="Имя"
                placeholder="Ваше имя"
                value={values.name}
                onChangeHandler={(e) => setFieldValue("name", e)}
                error={!!(errors.name && touched.name)}
                description={
                  errors.name &&
                  touched.name && <ErrorMessage name="name" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <Input
                name="phone"
                mode="secondary"
                title="Телефон"
                placeholder="8-900-123-55-77"
                value={values.phone}
                onChangeHandler={(e) => {
                  if (!e.match(/^\d{0,15}(\.\d{0,15}){0,1}$/)) {
                    return false;
                  }
                  setFieldValue("phone", e);
                }}
                error={!!(errors.phone && touched.phone)}
                description={
                  errors.phone &&
                  touched.phone && <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <Input
                name="address"
                mode="secondary"
                title="Адрес"
                placeholder="Город, улица, дом, подъезд"
                value={values.address}
                onChangeHandler={(e) => setFieldValue("address", e)}
                error={!!(errors.address && touched.address)}
                description={
                  errors.address &&
                  touched.address && <ErrorMessage name="address" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <Input
                name="message"
                mode="secondary"
                title="Комментарии"
                placeholder="Код домофона и тд"
                value={values.message}
                onChangeHandler={(e) => setFieldValue("message", e)}
                error={!!(errors.message && touched.message)}
                description={
                  errors.message &&
                  touched.message && <ErrorMessage name="message" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={10} />

              <div className="MyAddressPage_buttonBox">
                <PrimaryButton text="Добавить" mode={"primary"} btnType={"submit"} />
              </div>
            </Form>
          )}
        />
      </div>
    );
  };

  const renderMainBlock = () => {
    return (
      <div className="AddAddressHeader">
        <Card verticalPadding={3} horizontalPadding={3}>
          <div className="relative">
            <Card transparent={true} horizontalPadding={2} shadow={false}>
              <div className="AddAddressHeader_header">
                <div className="AddAddressHeader_header_left" />
                <div className="AddAddressHeader_header_center">
                  <span>Добавить адрес</span>
                </div>
                <div className="AddAddressHeader_header_right">
                  <div
                    className="AddAddressHeader_header_right_icon"
                    onClick={() => {
                      Modals.close();
                    }}>
                    <CloseSVG />
                  </div>
                </div>
              </div>

              <Spacer size={10} />
              <Spacer direction="vertical" size={4} />

              {renderForm()}

              <Spacer direction="vertical" size={4} />
            </Card>
          </div>
        </Card>
      </div>
    );
  };

  return renderMainBlock();
};

export default RegistrationContent;

import "./index.scss";
import React from "react";
import MobileHeader from "../../../../components/ui/mobile/MobileHeader";
import router5 from "../../../../router";
import { PAGE_ROUTES } from "../../../../definitions";
import { ReactComponent as AddSVG } from "../../../../static/images/svg/new/left_chevron.svg";
import HeaderTitle from "../../../../components/ui/mobile/HeaderTitle";
import { ReactComponent as OkSVG } from "../../../../static/images/svg/new/Ok.svg";
import PrimaryButton from "../../../../components/ui/universal/PrimaryButton";
import Spacer from "../../../../components/ui/universal/Spacer";

const SuccessOrder = () => {
  return (
    <div className="SuccessOrder">
      <div>
        <MobileHeader
          left={
            <div
              className="CitiesPage_header_icon"
              onClick={() => {
                router5.navigate(PAGE_ROUTES.MAIN);
              }}>
              <AddSVG />
            </div>
          }
          right={<div />}>
          <HeaderTitle text="На главную" align="left" />
        </MobileHeader>
      </div>
      <div className="SuccessOrder_box">
        <div className="SuccessOrder_box_header">
          <span>Ваш заказ успешно создан!</span>
        </div>
        <div className="SuccessOrder_box_icon">
          <OkSVG />
        </div>
        <PrimaryButton
          text="Посмотреть заказы"
          onClick={() => {
            router5.navigate(PAGE_ROUTES.MYORDERS);
          }}
        />
        <Spacer size={5} />
        <PrimaryButton
          text="Вернуться к покупкам"
          mode="primary"
          onClick={() => {
            router5.navigate(PAGE_ROUTES.MAIN);
          }}
        />
      </div>
    </div>
  );
};

export default SuccessOrder;

import "./index.scss";

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { isEmptyObject } from "../../../utils";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Input from "../../../components/ui/universal/Input";
import Card from "../../../components/ui/universal/Card";
import Spacer from "../../../components/ui/universal/Spacer";
import "../../../static/styles/input-phone.scss";
import { ISignInContent } from "./interface";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import * as Modals from "../../../components/ui/universal/Modals";
// @ts-ignore
import Textarea from "../../../components/ui/universal/Textarea";
import { commonActions } from "../../../store/actions/common-actions";
import cogoToast from "cogo-toast";
import Loading from "../../../components/ui/universal/Loading";
import router from "../../../router";

const REQUIRED_FIELD_ERROR: any = "Обязательное поле";

const RegistrationContent: React.FC<ISignInContent> = (props: any) => {
  const [isLoadingQueryUpdate, setIsLoadingQueryUpdate] = useState(false);
  const dispatch = useDispatch();
  const { params: routeParams } = router.getState();
  const { id: point, subjectId } = routeParams;
  const isLoaded = isEmptyObject(subjectId);

  if (isLoaded) {
    return <Loading />;
  }

  const initialFormikState = {
    description: "",
    price: null,
    title: "",
    priority: null,
  };

  const formikValidationSchema = Yup.object().shape({
    title: Yup.string().required(REQUIRED_FIELD_ERROR).min(1, "Введите название опции"),
    price: Yup.number().required(REQUIRED_FIELD_ERROR).nullable().min(1, "Введите цену"),
    priority: Yup.number().required(REQUIRED_FIELD_ERROR).nullable().min(1, "Введите приоритет при выдаче"),
    description: Yup.string().min(6, "Не менее 6 символов").required(REQUIRED_FIELD_ERROR),
  });

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={initialFormikState}
          validationSchema={formikValidationSchema}
          onSubmit={(values: any, { setSubmitting, setErrors }) => {
            values.subject_id = subjectId;
            const addVariations: any = dispatch(commonActions.addVariations(values));
            addVariations.then(
              (data: any) => {
                setSubmitting(true);
                cogoToast.success("Сохранено!");
                setIsLoadingQueryUpdate(false);
                Modals.close();
              },
              ({ errors }: any) => {
                errors.forEach(({ code }: any) => {});

                setSubmitting(false);
                cogoToast.error("Ошибка!");
              },
            );
          }}
          render={({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Spacer size={5} />

              <Input
                name="title"
                mode="secondary"
                title="Название товара"
                placeholder="Введите название товара"
                value={values.title}
                onChangeHandler={(e) => setFieldValue("title", e)}
                error={!!(errors.title && touched.title)}
                description={
                  errors.title &&
                  touched.title && <ErrorMessage name="title" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <div>
                <Textarea
                  title="Описание товара"
                  maxRows={5}
                  minRows={5}
                  placeholder="Введите название"
                  name="description"
                  value={values.description}
                  onChangeHandler={(e) => {
                    setFieldValue("description", e);
                  }}
                  // @ts-ignore
                  error={!!(errors.description && touched.description)}
                  description={
                    errors.description &&
                    touched.description && (
                      <ErrorMessage name="description" component="div" className="invalid-feedback" />
                    )
                  }
                />
              </div>

              <Spacer size={15} />

              <Input
                name="price"
                mode="secondary"
                title="Цена"
                placeholder="Цена в рублях"
                value={values.price}
                onChangeHandler={(e) => setFieldValue("price", e)}
                error={!!(errors.price && touched.price)}
                description={
                  errors.price &&
                  touched.price && <ErrorMessage name="price" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <Input
                name="priority"
                mode="secondary"
                title="Числовой приоритет"
                placeholder="Введите числовой приоритет"
                value={values.priority}
                onChangeHandler={(e) => setFieldValue("priority", e)}
                error={!!(errors.priority && touched.priority)}
                description={
                  errors.priority &&
                  touched.priority && <ErrorMessage name="priority" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={10} />

              <div className="MyAddressPage_buttonBox">
                <PrimaryButton text="Добавить" mode={"primary"} btnType={"submit"} />
              </div>
            </Form>
          )}
        />
      </div>
    );
  };

  const renderMainBlock = () => {
    return (
      <div className="AddVariationsHeader">
        <Card verticalPadding={3} horizontalPadding={3}>
          <div className="relative">
            <Card transparent={true} horizontalPadding={2} shadow={false}>
              <div className="AddVariationsHeader_header">
                <div className="AddVariationsHeader_header_left" />
                <div className="AddVariationsHeader_header_center">
                  <span>Добавить опцию</span>
                </div>
                <div className="AddVariationsHeader_header_right" />
              </div>

              <Spacer size={10} />
              <Spacer direction="vertical" size={4} />

              {renderForm()}

              <Spacer direction="vertical" size={4} />
            </Card>
          </div>
        </Card>
      </div>
    );
  };

  return renderMainBlock();
};

export default RegistrationContent;

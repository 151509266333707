import "./index.scss";
import React from "react";
import { ReactComponent as FotoSVG } from "../../../static/images/svg/new/foto.svg";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Spacer from "../../../components/ui/universal/Spacer";
import Input from "../../../components/ui/universal/Input";
import Textarea from "../../../components/ui/universal/Textarea";
import Cell from "../../../components/ui/mobile/Cell";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";

const REQUIRED_FIELD_ERROR = "Обязательное поле";

const CreatePointPage = () => {
  const InitialFormikState = {
    name: "",
    shortDescription: "",
    fullDescription: "",
    leadTime: "",
    averageCheck: "",
    freeDelivery: "",
    deliveryPrice: "",
    phone: "",
    pointEmail: "",
    pointAddress: "",
  };

  const formikValidationSchema = Yup.object().shape({
    login: Yup.string().required(REQUIRED_FIELD_ERROR),
  });

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={InitialFormikState}
          validationSchema={formikValidationSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {}}
          render={({ errors, touched, values, setFieldValue }) => (
            <Form>
              <div className="CreatePointPage">
                <div className="CreatePointPage_logoWrapper">
                  <div className="CreatePointPage_logoWrapper_box">
                    <div className="CreatePointPage_logoWrapper_box_logo">
                      <FotoSVG />
                    </div>
                  </div>
                  <div className="CreatePointPage_logoWrapper_title">
                    <div className="CreatePointPage_logoWrapper_title_item">
                      <span>Фото заведения</span>
                    </div>
                    <div className="CreatePointPage_logoWrapper_title_download">
                      <span>Загрузить фото</span>
                    </div>
                  </div>
                </div>

                <div className="CreatePointPage_cover">
                  <div className="CreatePointPage_cover_name">
                    <span>Обложка</span>
                  </div>
                  <div className="CreatePointPage_cover_box">
                    <FotoSVG />
                  </div>
                  <div className="CreatePointPage_cover_download">
                    <span>Загрузить обложку</span>
                  </div>
                </div>
                <Spacer size={15} />
                <div className="CreatePointPage_centred">
                  <Input
                    title="Название заведения"
                    mode="secondary"
                    placeholder="Введите название"
                    name="name"
                    value={values.name}
                    onChangeHandler={(e) => setFieldValue("name", e)}
                    error={!!(errors.name && touched.name)}
                    description={
                      errors.name &&
                      touched.name && <ErrorMessage name="name" component="div" className="invalid-feedback" />
                    }
                  />
                </div>
                <Spacer size={15} />
                <div className="CreatePointPage_centred">
                  <Textarea
                    title="Краткое описание"
                    maxRows={5}
                    minRows={5}
                    placeholder="Введите краткое описание"
                    name="shortDescription"
                    value={values.shortDescription}
                    onChangeHandler={(e) => setFieldValue("shortDescription", e)}
                    error={!!(errors.shortDescription && touched.shortDescription)}
                    description={
                      errors.shortDescription &&
                      touched.shortDescription && (
                        <ErrorMessage name="shortDescription" component="div" className="invalid-feedback" />
                      )
                    }
                  />
                </div>
                <Spacer size={15} />
                <div className="CreatePointPage_centred">
                  <Textarea
                    title="Полное описание"
                    maxRows={5}
                    minRows={5}
                    placeholder="Введите полное описание"
                    name="fullDescription"
                    value={values.fullDescription}
                    onChangeHandler={(e) => setFieldValue("fullDescription", e)}
                    error={!!(errors.fullDescription && touched.fullDescription)}
                    description={
                      errors.fullDescription &&
                      touched.fullDescription && (
                        <ErrorMessage name="fullDescription" component="div" className="invalid-feedback" />
                      )
                    }
                  />
                </div>
                <Spacer size={15} />
                <div className="CreatePointPage_centred">
                  <Input
                    title={"Ср. время исполнения заказа (минуты)"}
                    mode="secondary"
                    placeholder="Введите количество минут"
                    name="leadTime"
                    value={values.leadTime}
                    onChangeHandler={(e) => setFieldValue("leadTime", e)}
                    error={!!(errors.leadTime && touched.leadTime)}
                    description={
                      errors.leadTime &&
                      touched.leadTime && <ErrorMessage name="leadTime" component="div" className="invalid-feedback" />
                    }
                  />
                </div>

                <Spacer size={15} />
                <div className="CreatePointPage_centred">
                  <Input
                    title={"Средний чек"}
                    mode="secondary"
                    placeholder="Введите размер среднего чека"
                    name="averageCheck"
                    value={values.averageCheck}
                    onChangeHandler={(e) => setFieldValue("averageCheck", e)}
                    error={!!(errors.averageCheck && touched.averageCheck)}
                    description={
                      errors.averageCheck &&
                      touched.averageCheck && (
                        <ErrorMessage name="averageCheck" component="div" className="invalid-feedback" />
                      )
                    }
                  />
                </div>

                <Spacer size={15} />
                <div className="CreatePointPage_centred">
                  <Input
                    title={"Бесплатная доставка “ОТ”"}
                    mode="secondary"
                    placeholder="Введите сумму"
                    name="freeDelivery"
                    value={values.freeDelivery}
                    onChangeHandler={(e) => setFieldValue("freeDelivery", e)}
                    error={!!(errors.freeDelivery && touched.freeDelivery)}
                    description={
                      errors.freeDelivery &&
                      touched.freeDelivery && (
                        <ErrorMessage name="freeDelivery" component="div" className="invalid-feedback" />
                      )
                    }
                  />
                </div>

                <Spacer size={15} />
                <div className="CreatePointPage_centred">
                  <Input
                    title={"Стоимость доставки"}
                    mode="secondary"
                    placeholder="Введите стоимость доставки"
                    name="deliveryPrice"
                    value={values.deliveryPrice}
                    onChangeHandler={(e) => setFieldValue("deliveryPrice", e)}
                    error={!!(errors.deliveryPrice && touched.deliveryPrice)}
                    description={
                      errors.deliveryPrice &&
                      touched.deliveryPrice && (
                        <ErrorMessage name="deliveryPrice" component="div" className="invalid-feedback" />
                      )
                    }
                  />
                </div>

                <Spacer size={15} />
                <div className="CreatePointPage_schedule">
                  <div className="CreatePointPage_schedule_header">
                    <span>Время работы</span>
                  </div>
                  <div className="CreatePointPage_schedule_item">
                    <span>Понедельник</span>
                    <div className="CreatePointPage_schedule_item_checkbox">
                      <Cell selectable></Cell>
                    </div>
                  </div>
                  <div className="CreatePointPage_schedule_item">
                    <span>Вторник</span>
                    <div className="CreatePointPage_schedule_item_checkbox">
                      <Cell selectable></Cell>
                    </div>
                  </div>
                  <div className="CreatePointPage_schedule_item">
                    <span>Среда</span>
                    <div className="CreatePointPage_schedule_item_checkbox">
                      <Cell selectable></Cell>
                    </div>
                  </div>
                  <div className="CreatePointPage_schedule_item">
                    <span>Четверг</span>
                    <div className="CreatePointPage_schedule_item_checkbox">
                      <Cell selectable></Cell>
                    </div>
                  </div>
                  <div className="CreatePointPage_schedule_item">
                    <span>Пятница</span>
                    <div className="CreatePointPage_schedule_item_checkbox">
                      <Cell selectable></Cell>
                    </div>
                  </div>
                  <div className="CreatePointPage_schedule_item">
                    <span>Суббота</span>
                    <div className="CreatePointPage_schedule_item_checkbox">
                      <Cell selectable></Cell>
                    </div>
                  </div>
                  <div className="CreatePointPage_schedule_item">
                    <span>Воскресенье</span>
                    <div className="CreatePointPage_schedule_item_checkbox">
                      <Cell selectable></Cell>
                    </div>
                  </div>
                </div>

                <Spacer size={15} />
                <div className="CreatePointPage_centred">
                  <Input
                    title={"Номер телефона"}
                    mode="secondary"
                    placeholder="Введите номер телефона"
                    name="phone"
                    value={values.phone}
                    onChangeHandler={(e) => setFieldValue("phone", e)}
                    error={!!(errors.phone && touched.phone)}
                    description={
                      errors.phone &&
                      touched.phone && <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                    }
                  />
                </div>

                <Spacer size={15} />
                <div className="CreatePointPage_centred">
                  <Input
                    title={"Электронная почта"}
                    mode="secondary"
                    placeholder="Введите электронную почту"
                    name="pointEmail"
                    value={values.pointEmail}
                    onChangeHandler={(e) => setFieldValue("pointEmail", e)}
                    error={!!(errors.pointEmail && touched.pointEmail)}
                    description={
                      errors.pointEmail &&
                      touched.pointEmail && (
                        <ErrorMessage name="pointEmail" component="div" className="invalid-feedback" />
                      )
                    }
                  />
                </div>

                <Spacer size={15} />
                <div className="CreatePointPage_centred">
                  <Input
                    title={"Адрес заведения"}
                    mode="secondary"
                    placeholder="Введите адрес заведения"
                    name="pointAddress"
                    value={values.address}
                    onChangeHandler={(e) => setFieldValue("address", e)}
                    error={!!(errors.address && touched.address)}
                    description={
                      errors.address &&
                      touched.address && <ErrorMessage name="address" component="div" className="invalid-feedback" />
                    }
                  />
                </div>

                <Spacer size={15} />
              </div>
            </Form>
          )}
        />
      </div>
    );
  };

  return (
    <div>
      {renderForm()}
      <div className="CreatePointPage_centred">
        <PrimaryButton text="Сохранить" mode={"primary"} type={"submit"} />
        <Spacer size={5} />
      </div>
    </div>
  );
};

export default CreatePointPage;

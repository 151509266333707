import { TYPES } from "../../actions";

const INITIAL_STATE = {
  isLoading: new Set(),
};

export const LoadingReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case TYPES.LOADING.SET: {
      const { isLoading } = state;
      isLoading.add(action.payload);

      return {
        ...state,
        isLoading,
      };
    }

    case TYPES.LOADING.REMOVE: {
      const { isLoading } = state;
      isLoading.delete(action.payload);

      return {
        ...state,
        isLoading,
      };
    }

    case TYPES.LOADING.CLEAR: {
      const { isLoading } = state;
      isLoading.delete(action.payload);

      return {
        ...state,
        isLoading: new Set(),
      };
    }

    default:
      return state;
  }
};

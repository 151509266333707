import "./index.scss";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyObject } from "../../../utils";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Input from "../../../components/ui/universal/Input";
import Card from "../../../components/ui/universal/Card";
import Spacer from "../../../components/ui/universal/Spacer";
import "../../../static/styles/input-phone.scss";
import { ISignInContent } from "./interface";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import * as Modals from "../../../components/ui/universal/Modals";
// @ts-ignore
import Textarea from "../../../components/ui/universal/Textarea";
import Switch from "../../../components/ui/universal/Switch";
import { commonActions } from "../../../store/actions/common-actions";
import cogoToast from "cogo-toast";
import Loading from "../../../components/ui/universal/Loading";

const REQUIRED_FIELD_ERROR: any = "Обязательное поле";

const RegistrationContent: React.FC<ISignInContent> = (props: any) => {
  const [isLoadingQueryUpdate, setIsLoadingQueryUpdate] = useState(false);
  const [editDisabled, setEditDisabled] = useState(false);
  const dispatch = useDispatch();

  const { variationId } = useSelector((store: any) => ({
    variationId: store.common.variationId,
  }));
  const isLoaded = isEmptyObject(variationId) || variationId.id !== props.variationItemId;

  const { title, description, price, priority } = variationId;

  useEffect(() => {
    dispatch(
      commonActions.getVariationsId({
        variation_id: props.variationItemId,
      }),
    );
  }, []);

  if (isLoaded) {
    return <Loading />;
  }

  const initialFormikState = {
    description,
    price,
    title,
    priority,
  };

  const formikValidationSchema = Yup.object().shape({
    title: Yup.string().required(REQUIRED_FIELD_ERROR),

    price: Yup.number().required(REQUIRED_FIELD_ERROR),

    priority: Yup.number().required(REQUIRED_FIELD_ERROR),

    description: Yup.string().min(6, "Не менее 6 символов").required(REQUIRED_FIELD_ERROR),
  });

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={initialFormikState}
          validationSchema={formikValidationSchema}
          onSubmit={(values: any, { setSubmitting, setErrors }) => {
            values.variation_id = props.variationItemId;
            values.disabled = +editDisabled;
            const editVariations: any = dispatch(commonActions.editVariations(values));

            editVariations.then(
              (data: any) => {
                setSubmitting(true);
                cogoToast.success("Сохранено!");
                setIsLoadingQueryUpdate(false);
                Modals.close();
              },
              ({ errors }: any) => {
                errors.forEach(({ code }: any) => {});

                setSubmitting(false);
                cogoToast.error("Ошибка!");
              },
            );
          }}
          render={({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Spacer size={5} />

              <Input
                name="title"
                mode="secondary"
                title="Название товара"
                placeholder="Введите название товара"
                value={values.title}
                onChangeHandler={(e) => setFieldValue("title", e)}
                error={!!(errors.title && touched.title)}
                description={
                  errors.title &&
                  touched.title && <ErrorMessage name="title" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <div>
                <Textarea
                  title="Описание товара"
                  maxRows={5}
                  minRows={5}
                  placeholder="Введите название"
                  name="description"
                  value={values.description}
                  onChangeHandler={(e) => {
                    setFieldValue("description", e);
                  }}
                  // @ts-ignore
                  error={!!(errors.description && touched.description)}
                  description={
                    errors.description &&
                    touched.description && (
                      <ErrorMessage name="description" component="div" className="invalid-feedback" />
                    )
                  }
                />
              </div>

              <Spacer size={15} />

              <Input
                name="price"
                mode="secondary"
                title="Цена"
                placeholder="Цена в рублях"
                value={values.price}
                onChangeHandler={(e) => setFieldValue("price", e)}
                error={!!(errors.price && touched.price)}
                description={
                  errors.price &&
                  touched.price && <ErrorMessage name="price" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={15} />

              <Input
                name="priority"
                mode="secondary"
                title="Числовой приоритет"
                placeholder="Введите числовой приоритет"
                value={values.priority}
                onChangeHandler={(e) => setFieldValue("priority", e)}
                error={!!(errors.priority && touched.priority)}
                description={
                  errors.priority &&
                  touched.priority && <ErrorMessage name="priority" component="div" className="invalid-feedback" />
                }
              />

              <Spacer size={10} />

              <div className="EditSubject_switch">
                <span>Отображать товар</span>
                <div className="EditSubject_switch_box">
                  <Switch on={editDisabled} onChange={() => setEditDisabled(!editDisabled)} />
                </div>
              </div>

              <div className="MyAddressPage_buttonBox">
                <PrimaryButton text="Добавить" mode={"primary"} btnType={"submit"} />
              </div>
            </Form>
          )}
        />
      </div>
    );
  };

  const renderMainBlock = () => {
    return (
      <div className="EditSubjectHeader">
        <Card verticalPadding={3} horizontalPadding={3}>
          <div className="relative">
            <Card transparent={true} horizontalPadding={2} shadow={false}>
              <div className="EditSubjectHeader_header">
                <div className="EditSubjectHeader_header_left" />
                <div className="EditSubjectHeader_header_center">
                  <span>Изменить опцию</span>
                </div>
                <div className="AddAdditionsHeader_header_right" />
              </div>

              <Spacer size={10} />

              <Spacer direction="vertical" size={4} />

              {renderForm()}

              <Spacer direction="vertical" size={4} />
            </Card>
          </div>
        </Card>
      </div>
    );
  };

  return renderMainBlock();
};

export default RegistrationContent;

import "./index.scss";
import React, { useEffect, useState } from "react";
import { useGetMyPointInfo } from "../../../hooks";
import Loading from "../../../components/ui/universal/Loading";
import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import Spacer from "../../../components/ui/universal/Spacer";
import Input from "../../../components/ui/universal/Input";
import Textarea from "../../../components/ui/universal/Textarea";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import { useDispatch } from "react-redux";
import Switch from "../../../components/ui/universal/Switch";
import cogoToast from "cogo-toast";
import { commonActions } from "../../../store/actions";
import { v4 as uuidv4 } from "uuid";
import getDayByNumber from "../../../utils/getDayByNumber";
import SimpleCheckbox from "../../../components/ui/universal/SimpleCheckbox";
import ImageUpload from "../../../components/common/UploadPhoto";

const REQUIRED_FIELD_ERROR = "Обязательное поле";
const validationUrl = /^(ftp|http|https):\/\/[^ "]+$/;

const GeneralPointSettings = () => {
  const dispatch = useDispatch();
  const { isLoadedThisPoint, pointInfo, pointId } = useGetMyPointInfo();
  const [isDisabled, setIsDisabled] = useState(!!pointInfo ? !!pointInfo.disabled : false);
  const [isLoadingQueryUpdate, setIsLoadingQueryUpdate] = useState(false);
  const [openModalAvatar, setOpenModalAvatar] = useState(false);
  const [openModalCover, setOpenModalCover] = useState(false);
  const [avatarPreviewUrl, setAvatarPreviewUrl] = useState("");
  const [avatarHash, setAvatarHash] = useState("");
  const [coverPreviewUrl, setCoverPreviewUrl] = useState("");
  const [coverHash, setCoverHash] = useState("");

  useEffect(() => {
    if (!!pointInfo) {
      setIsDisabled(pointInfo.disabled);
    }
  }, [isLoadedThisPoint]);

  if (!isLoadedThisPoint) {
    return <Loading />;
  }

  const {
    title,
    description,
    short_description,
    average_lead_time_on_minutes,
    minimal_order,
    work_days,
    average_check,
    delivery_free_from,
    delivery_price,
    phones,
    legal_information,
    addresses,
    vk_link,
    whatsapp_link,
    telegram_link,
    cover,
    logotype,
  } = pointInfo;

  const InitialFormikState = {
    title,
    description,
    short_description,
    average_lead_time_on_minutes,
    minimal_order,
    work_days,
    average_check,
    delivery_free_from,
    delivery_price,
    phones,
    legal_information,
    addresses,
    vk_link,
    whatsapp_link,
    telegram_link,
    cover,
    logotype,
  };

  const formikValidationSchema = Yup.object().shape({
    title: Yup.string().min(2, "Не менее 2 символов").required(REQUIRED_FIELD_ERROR),

    description: Yup.string().min(10, "Не менее 10 символов").required(REQUIRED_FIELD_ERROR),

    short_description: Yup.string().min(10, "Не менее 10 символов").required(REQUIRED_FIELD_ERROR),

    delivery_price: Yup.number().nullable().min(1, "Введите стоимость доставки").required(REQUIRED_FIELD_ERROR),

    average_check: Yup.number().nullable().min(1, "Введите средний чек").required(REQUIRED_FIELD_ERROR),

    delivery_free_from: Yup.number()
      .nullable()
      .min(1, "Введите стоимость бесплатной доставки")
      .required(REQUIRED_FIELD_ERROR),

    average_lead_time_on_minutes: Yup.number()
      .nullable()
      .min(1, "Введите время исполнения заказа")
      .required(REQUIRED_FIELD_ERROR),

    // cover: Yup.string()
    //   .required(REQUIRED_FIELD_ERROR),
    //
    // logotype: Yup.string()
    //   .required(REQUIRED_FIELD_ERROR),

    phones: Yup.string()
      .max(50, "Номерa телефонов не могут быть длиннее 50 символов")
      .min(10, "Номер телефона не может быть короче 10 символов")
      .required(REQUIRED_FIELD_ERROR),

    minimal_order: Yup.number().nullable().min(1, "Введите минимальный заказ").required(REQUIRED_FIELD_ERROR),

    addresses: Yup.string().required(REQUIRED_FIELD_ERROR),

    work_days: Yup.string().required(REQUIRED_FIELD_ERROR),

    telegram_link: Yup.string().max(25, "Максимум 25 символов"),

    whatsapp_link: Yup.string(),

    vk_link: Yup.string(),
  });

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={InitialFormikState}
          validationSchema={formikValidationSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const _values = values;
            if (!avatarHash.length) {
              delete _values.logotype;
            } else {
              _values.logotype = avatarHash;
            }
            if (!coverHash.length) {
              delete _values.cover;
            } else {
              _values.cover = coverHash;
            }
            _values.disabled = +isDisabled;
            _values.point_id = pointId;
            if (isLoadingQueryUpdate) return;
            const editPoint = dispatch(commonActions.editPoint(_values));
            editPoint.then(
              (data) => {
                setSubmitting(true);
                cogoToast.success("Сохранено!");
                setIsLoadingQueryUpdate(false);
              },
              ({ errors }) => {
                errors.forEach(({ code }) => {
                  switch (code) {
                    case "PERMISSION_DENIED":
                      setErrors({
                        phones: "Неверный",
                      });
                      setSubmitting(false);
                      break;
                  }
                });
                setSubmitting(false);
                cogoToast.error("Ошибка!");
              },
            );
          }}
          render={({ errors, touched, values, setFieldValue }) => {
            const toggleWorkDaysHandler = (num) => {
              let currentWorkDays = ("" + values.work_days).split("").map((item) => parseInt(item));
              const searchResultKey = currentWorkDays.indexOf(num);
              if (searchResultKey === -1) {
                currentWorkDays.push(parseInt(num));
              } else {
                currentWorkDays = currentWorkDays.filter((i) => i !== currentWorkDays[searchResultKey]);
              }
              let response = currentWorkDays.sort((a, b) => a - b);
              if (!response.length) return -1;
              if (response.indexOf(0) > -1) {
                response = response.filter((i) => i !== 0);
                return +(response.join("") + "0");
              }
              return +response.join("");
            };

            const renderDays = () => {
              const currentWorkDays = ("" + values.work_days).split("").map((item) => parseInt(item));
              const renderTSXDays = [];
              for (let i = 0; i <= 6; i++) {
                renderTSXDays.push(
                  <div key={uuidv4()} className="no_select">
                    <div className="GeneralPointSettings_schedule_item">
                      <div className="GeneralPointSettings_schedule_item_left">{getDayByNumber(i)}</div>
                      <div className="GeneralPointSettings_schedule_item_checkbox">
                        <SimpleCheckbox
                          checked={currentWorkDays.indexOf(i) > -1}
                          onClick={(e) => {
                            const toggleWorkDays = toggleWorkDaysHandler(i);
                            setFieldValue("work_days", toggleWorkDays === -1 ? i : toggleWorkDays);
                          }}
                        />
                      </div>
                    </div>
                  </div>,
                );
              }
              return renderTSXDays;
            };

            return (
              <Form>
                <div className="GeneralPointSettings">
                  <div className="GeneralPointSettings_logoWrapper">
                    <div className="GeneralPointSettings_logoWrapper_box">
                      <div
                        className="GeneralPointSettings_logoWrapper_box_logo"
                        //style={{ backgroundImage: !avatarPreviewUrl.length ? (!logotype.length ? `url(${logotype[150]})` : `url(${avatarPreviewUrl})`) :`url(${avatarPreviewUrl})`}}
                        onClick={() => {
                          setOpenModalAvatar(!openModalAvatar);
                        }}>
                        {/*{ logotype.isDefault && (*/}
                        {/*   <FotoSVG/>*/}
                        {/*)}*/}
                      </div>

                      {openModalAvatar && (
                        <div>
                          <ImageUpload
                            onChangeHandler={({ imagePreviewUrl, hash }) => {
                              setAvatarPreviewUrl(imagePreviewUrl);
                              setAvatarHash(hash);
                            }}
                            onClickClose={() => {
                              setOpenModalAvatar(!openModalAvatar);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="GeneralPointSettings_logoWrapper_title">
                      <div className="GeneralPointSettings_logoWrapper_title_item">
                        <span>Фото заведения</span>
                      </div>
                      <div className="GeneralPointSettings_logoWrapper_title_download">
                        <span>Загрузить фото</span>
                      </div>
                    </div>
                  </div>

                  <div className="GeneralPointSettings_logoWrapper_switch">
                    <div className="GeneralPointSettings_logoWrapper_switch_box">
                      <Switch on={isDisabled} onChange={() => setIsDisabled(!isDisabled)} />
                    </div>
                    <span>Показывать в поиске:</span>
                    <span>{+isDisabled === 1 ? "Нет" : "Да"}</span>
                  </div>

                  <div className="GeneralPointSettings_cover">
                    {openModalCover && (
                      <div>
                        <ImageUpload
                          onChangeHandler={({ imagePreviewUrl, hash }) => {
                            setCoverPreviewUrl(imagePreviewUrl);
                            setCoverHash(hash);
                          }}
                          onClickClose={() => {
                            setOpenModalCover(!openModalCover);
                          }}
                        />
                      </div>
                    )}
                    <div className="GeneralPointSettings_cover_name">
                      <span>Обложка</span>
                    </div>
                    <div
                      className="GeneralPointSettings_cover_box"
                      // style={{ backgroundImage: !coverPreviewUrl.length ? (!cover.length ? `url(${cover[450]})` : `url(${coverPreviewUrl})`) :`url(${coverPreviewUrl})`}}
                      onClick={() => {
                        setOpenModalCover(!openModalCover);
                      }}>
                      {/*{cover.isDefault && (*/}
                      {/*    <FotoSVG/>*/}
                      {/*  )}*/}
                    </div>
                    <div className="GeneralPointSettings_cover_download">
                      <span>Загрузить обложку</span>
                    </div>
                  </div>
                  <Spacer size={15} />
                  <div className="GeneralPointSettings_centred">
                    <Input
                      title="Название заведения"
                      mode="secondary"
                      placeholder="Введите название"
                      name="title"
                      value={values.title}
                      onChangeHandler={(e) => setFieldValue("title", e)}
                      error={!!(errors.title && touched.title)}
                      description={
                        errors.title &&
                        touched.title && <ErrorMessage name="title" component="div" className="invalid-feedback" />
                      }
                    />
                  </div>

                  <Spacer size={15} />
                  <div className="GeneralPointSettings_centred">
                    <Textarea
                      maxRows={5}
                      minRows={5}
                      title="Полное описание заведения"
                      mode="secondary"
                      placeholder="Введите название"
                      name="description"
                      value={values.description}
                      onChangeHandler={(e) => {
                        setFieldValue("description", e);
                      }}
                      error={!!(errors.description && touched.description)}
                      description={
                        errors.description &&
                        touched.description && (
                          <ErrorMessage name="description" component="div" className="invalid-feedback" />
                        )
                      }
                    />
                  </div>

                  <Spacer size={15} />
                  <div className="GeneralPointSettings_centred">
                    <Textarea
                      maxRows={5}
                      minRows={5}
                      title="Рекламный слоган, основные фишки (будут видны на главной странице)"
                      mode="secondary"
                      placeholder="Введите название"
                      name="short_description"
                      value={values.short_description}
                      onChangeHandler={(e) => {
                        setFieldValue("short_description", e);
                      }}
                      error={!!(errors.short_description && touched.short_description)}
                      description={
                        errors.short_description &&
                        touched.short_description && (
                          <ErrorMessage name="short_description" component="div" className="invalid-feedback" />
                        )
                      }
                    />
                  </div>

                  <Spacer size={15} />

                  <div className="GeneralPointSettings_centred">
                    <Input
                      title={"Ср. время исполнения заказа (минуты)"}
                      mode="secondary"
                      placeholder="Введите количество минут"
                      name="average_lead_time_on_minutes"
                      value={values.average_lead_time_on_minutes}
                      onChangeHandler={(e) => setFieldValue("average_lead_time_on_minutes", e)}
                      error={!!(errors.average_lead_time_on_minutes && touched.average_lead_time_on_minutes)}
                      description={
                        errors.average_lead_time_on_minutes &&
                        touched.average_lead_time_on_minutes && (
                          <ErrorMessage
                            name="average_lead_time_on_minutes"
                            component="div"
                            className="invalid-feedback"
                          />
                        )
                      }
                    />
                  </div>

                  <Spacer size={15} />
                  <div className="GeneralPointSettings_centred">
                    <Input
                      title={"Средний чек"}
                      mode="secondary"
                      placeholder="Введите размер среднего чека"
                      name="average_check"
                      value={values.average_check}
                      onChangeHandler={(e) => setFieldValue("average_check", e)}
                      error={!!(errors.average_check && touched.average_check)}
                      description={
                        errors.average_check &&
                        touched.average_check && (
                          <ErrorMessage name="average_check" component="div" className="invalid-feedback" />
                        )
                      }
                    />
                  </div>

                  <Spacer size={15} />
                  <div className="GeneralPointSettings_centred">
                    <Input
                      title={"Минимальный заказ от"}
                      mode="secondary"
                      placeholder="Введите размер среднего чека"
                      name="minimal_order"
                      value={values.minimal_order}
                      onChangeHandler={(e) => setFieldValue("minimal_order", e)}
                      error={!!(errors.minimal_order && touched.minimal_order)}
                      description={
                        errors.minimal_order &&
                        touched.minimal_order && (
                          <ErrorMessage name="minimal_order" component="div" className="invalid-feedback" />
                        )
                      }
                    />
                  </div>

                  <Spacer size={15} />
                  <div className="GeneralPointSettings_centred">
                    <Input
                      title={"Бесплатная доставка “ОТ”"}
                      mode="secondary"
                      placeholder="Введите сумму"
                      name="delivery_free_from"
                      value={values.delivery_free_from}
                      onChangeHandler={(e) => setFieldValue("delivery_free_from", e)}
                      error={!!(errors.delivery_free_from && touched.delivery_free_from)}
                      description={
                        errors.delivery_free_from &&
                        touched.delivery_free_from && (
                          <ErrorMessage name="delivery_free_from" component="div" className="invalid-feedback" />
                        )
                      }
                    />
                  </div>

                  <Spacer size={15} />
                  <div className="GeneralPointSettings_centred">
                    <Input
                      title={"Стоимость доставки"}
                      mode="secondary"
                      placeholder="Введите стоимость доставки"
                      name="delivery_price"
                      value={values.delivery_price}
                      onChangeHandler={(e) => setFieldValue("delivery_price", e)}
                      error={!!(errors.delivery_price && touched.delivery_price)}
                      description={
                        errors.delivery_price &&
                        touched.delivery_price && (
                          <ErrorMessage name="delivery_price" component="div" className="invalid-feedback" />
                        )
                      }
                    />
                  </div>

                  <Spacer size={15} />

                  <div className="GeneralPointSettings_schedule">
                    <div className="GeneralPointSettings_schedule_header">
                      <span>Время работы</span>
                    </div>
                    <div>{renderDays()}</div>
                  </div>

                  <Spacer size={15} />
                  <div className="GeneralPointSettings_centred">
                    <Input
                      title={"Номер телефона"}
                      mode="secondary"
                      placeholder="Введите номер телефона"
                      name="phones"
                      value={values.phones}
                      onChangeHandler={(e) => setFieldValue("phones", e)}
                      error={!!(errors.phones && touched.phones)}
                      description={
                        errors.phones &&
                        touched.phones && <ErrorMessage name="phones" component="div" className="invalid-feedback" />
                      }
                    />
                  </div>

                  <Spacer size={15} />
                  <div className="GeneralPointSettings_centred">
                    <Input
                      title={"Ссылка на whatsapp"}
                      mode="secondary"
                      placeholder="Введите номер телефона"
                      name="whatsapp_link"
                      value={values.whatsapp_link}
                      onChangeHandler={(e) => {
                        if (!e.match(/^\d{0,15}(\.\d{0,15}){0,1}$/)) {
                          return false;
                        }
                        setFieldValue("whatsapp_link", e);
                      }}
                      error={!!(errors.whatsapp_link && touched.whatsapp_link)}
                      description={
                        errors.whatsapp_link &&
                        touched.whatsapp_link && (
                          <ErrorMessage name="whatsapp_link" component="div" className="invalid-feedback" />
                        )
                      }
                    />
                    <div className="GeneralPointSettings_description-input">
                      <span>* Номер телефона к которому привязан ваш аккаунт</span>
                    </div>
                  </div>

                  <Spacer size={15} />
                  <div className="GeneralPointSettings_centred">
                    <Input
                      icon={"@"}
                      title={"Ссылка на Telegram"}
                      mode="secondary"
                      placeholder="Введите информацию"
                      name="telegram_link"
                      value={values.telegram_link}
                      onChangeHandler={(e) => {
                        if (!e.match(/^[a-zA-Z0-9._]+$/)) {
                          return false;
                        }
                        setFieldValue("telegram_link", e);
                      }}
                      error={!!(errors.telegram_link && touched.telegram_link)}
                      description={
                        errors.telegram_link &&
                        touched.telegram_link && (
                          <ErrorMessage name="telegram_link" component="div" className="invalid-feedback" />
                        )
                      }
                    />
                    <div className="GeneralPointSettings_description-input">
                      <span>* Введите ваше имя пользователя</span>
                    </div>
                  </div>

                  <Spacer size={15} />
                  <div className="GeneralPointSettings_centred">
                    <Input
                      title={"Ссылка Vk"}
                      mode="secondary"
                      placeholder="Введите информацию"
                      name="vk_link"
                      value={values.vk_link}
                      onChangeHandler={(e) => {
                        if (!e.match(validationUrl)) {
                          return false;
                        }
                        setFieldValue("vk_link", e);
                      }}
                      error={!!(errors.vk_link && touched.vk_link)}
                      description={
                        errors.vk_link &&
                        touched.vk_link && <ErrorMessage name="vk_link" component="div" className="invalid-feedback" />
                      }
                    />
                    <div className="GeneralPointSettings_description-input">
                      <span>* Укажите прямую ссылку на ваш профиль/сообщество</span>
                    </div>
                  </div>

                  <Spacer size={15} />
                  <div className="GeneralPointSettings_centred">
                    <Input
                      title={"Легальная информация"}
                      mode="secondary"
                      placeholder="Введите информацию"
                      name="legal_information"
                      value={values.legal_information}
                      onChangeHandler={(e) => setFieldValue("legal_information", e)}
                      error={!!(errors.legal_information && touched.legal_information)}
                      description={
                        errors.legal_information &&
                        touched.legal_information && (
                          <ErrorMessage name="legal_information" component="div" className="invalid-feedback" />
                        )
                      }
                    />
                  </div>

                  <Spacer size={15} />
                  <div className="GeneralPointSettings_centred">
                    <Input
                      title={"Адрес заведения"}
                      mode="secondary"
                      placeholder="Введите адрес заведения"
                      name="addresses"
                      value={values.addresses}
                      onChangeHandler={(e) => setFieldValue("addresses", e)}
                      error={!!(errors.addresses && touched.addresses)}
                      description={
                        errors.addresses &&
                        touched.addresses && (
                          <ErrorMessage name="addresses" component="div" className="invalid-feedback" />
                        )
                      }
                    />
                  </div>

                  <Spacer size={15} />
                </div>
                <div>
                  <div className="GeneralPointSettings_centred">
                    <PrimaryButton text="Сохранить" mode="primary" btnType="submit" />
                    <Spacer size={5} />
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </div>
    );
  };

  return <div>{renderForm()}</div>;
};

export default GeneralPointSettings;

import React from "react";
import { connect } from "react-redux";
import { usersActions } from "../../../store/actions";
import BaseContainer from "../BaseContainer";
import Loading from "../../../components/ui/universal/Loading";

class Index extends BaseContainer {
  state = {
    error: false,
  };

  componentDidMount() {
    const { id } = this.props.routeState.params;
    this.props.userGet(id).then(
      (data) => {},
      (error) => {
        if (!error.status) {
          this.setState({
            error: true,
          });
        }
      },
    );
  }

  renderProfile = ({ user }) => {
    return <div>{JSON.stringify(user)}</div>;
  };

  render() {
    const { id } = this.props.routeState.params;

    if (this.state.error) {
      return (
        <div>
          <div>Ошибка загрузки</div>
          <div>
            <a
              href="#"
              onClick={() => {
                window.location.reload();
              }}>
              Перезагрузить?
            </a>
          </div>
        </div>
      );
    }

    return !this.props.users[id] ? (
      <div>
        <Loading />
      </div>
    ) : (
      <div>
        {this.renderProfile({
          user: this.props.users[id],
        })}
      </div>
    );
  }
}

const mapState2Props = (state) => {
  return {
    personal: state.personal,
    ...state.users,
    ...state.loading,
  };
};

const mapDispatch2Props = {
  userGet: usersActions.get,
};

export default connect(mapState2Props, mapDispatch2Props)(Index);

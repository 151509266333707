import "./index.scss";
import React, { useState } from "react";
import ReactPinField from "react-pin-field";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import Spacer from "../../ui/universal/Spacer";
import { useApiRequest } from "../../../services/api/APIProvider";
import { useAuthSetAccessToken } from "../../../services/api/AuthContext";
import * as Modals from "../../ui/universal/Modals";
import router from "../../../router";
import { PAGE_ROUTES } from "../../../definitions";
import PrimaryButton from "../../ui/universal/PrimaryButton";
import ModalError from "../ModalError";

const PinCode = (props) => {
  const { codeSession, exists } = props;
  const [error, setError] = useState();
  const [pinCode, setPinCode] = useState("");
  const apiRequest = useApiRequest();
  const setAccessToken = useAuthSetAccessToken();

  const initialFormikState = {
    code: pinCode,
  };

  const formikValidationSchema = Yup.object().shape({});

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={initialFormikState}
          validationSchema={formikValidationSchema}
          onSubmit={(values) => {
            apiRequest("auth.confirmCode", {
              session_id: codeSession.codeSession,
              code: pinCode,
            })
              .then(({ response }) => {
                if (!response.required_extend) {
                  return apiRequest("auth.loginAccount", {
                    session_id: response.session_id,
                  })
                    .then(({ response }) => {
                      setAccessToken(response);
                      router.navigate(PAGE_ROUTES.MAIN);
                    })
                    .catch((r) => setError(r.error));
                }
                Modals.open("reg", { session: response.session_id }, {}, {});
              })
              .catch((r) => setError(r.error));
          }}
          render={({ errors, touched, values, setFieldValue }) => (
            <Form>
              <div className="PinCode_description">
                <span>
                  {exists
                    ? "Введите ваш 6-значный код доступа для авторизации"
                    : "Придумайте ваш 6-значный код доступа, он потребуется для авторизации в дальнейшем"}
                </span>
              </div>
              <Spacer size={10} />
              <div className="PinCode_input-box">
                <div className="PinCode_input-box_wrapper">
                  <ReactPinField className="pin-field" length={6} id="field" onChange={(val) => setPinCode(val)} />
                </div>
              </div>

              {error && <ModalError error={error.error_message} modalClose={() => setError(undefined)} />}
              <Spacer size={10} />
              <div className="PinCode_buttonBox">
                <PrimaryButton text="Продолжить" mode="primary" btnType="submit" />
              </div>
            </Form>
          )}
        />
      </div>
    );
  };

  return <div className="PinCode">{renderForm()}</div>;
};

export default PinCode;

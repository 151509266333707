import "./index.scss";
import React from "react";
import CounterInput from "react-counter-input";

const AdditionalServices = (props) => {
  const { id, description, name, price, onChangeHandler, preDefaultCounter = 0, count } = props;

  const btn = {
    borderRadius: "30px",
    backgroundColor: "#a380ff",
    color: "white",
    paddingTop: "6px",
    paddingLeft: "10px",
    width: "30px",
    height: "30px",
  };
  const wrp = {
    height: "30px",
  };

  return (
    <div className="AdditionalServices_option">
      <div className="AdditionalServices_option_item">
        <div className="AdditionalServices_option_item_left">
          <span>{name}</span>
          <div className="AdditionalServices_option_item_left_small">{description}</div>
        </div>
        <div className="AdditionalServices_option_item_right">
          {price === 0 && <span>+ 0₽</span>}
          {!!price && (
            <span style={!!count ? { color: "#6933a2" } : {}}>
              + {count > 1 && count * price}
              {count < 2 && price}₽
            </span>
          )}
        </div>
        <div className="AdditionalServices_option_item_rightCounter">
          <div className="AdditionalServices_option_item_rightCounter_center">
            <CounterInput
              wrapperStyle={wrp}
              btnStyle={btn}
              min={0}
              max={10}
              count={!!count ? count : preDefaultCounter}
              onCountChange={(value) => {
                onChangeHandler({
                  id,
                  count: value,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalServices;

import "./index.scss";
import React from "react";

const ModalError = (props) => {
  const { error, modalClose } = props;
  return (
    <div className="ModalError" onClick={modalClose}>
      <div className="ModalError_message">
        <span>{error}</span>
      </div>
    </div>
  );
};

export default ModalError;

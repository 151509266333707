import "./index.scss";
import React from "react";
import { ReactComponent as NewPlusSVG } from "../../../static/images/svg/new/newPlus.svg";
import { ReactComponent as NewMinusSVG } from "../../../static/images/svg/new/newMinus.svg";
import classNames from "../../../utils/classNames";
const Counter = (props) => {
  const increment = () => {
    if (props.value + 1 <= props.maxValue) {
      const nv = props.value + 1;
      props.onChange(nv);
    }
  };

  const decrement = () => {
    if (props.value - 1 >= props.minValue) {
      const nv = props.value - 1;
      props.onChange(nv);
    }
  };

  const renderMain = () => {
    return (
      <>
        <div className="Counter__main">
          <div
            onClick={() => increment()}
            className={classNames("Counter__every Counter__action Counter__increment", {
              [`Counter__notEmpty`]: props.value > 0,
            })}>
            <span>
              <NewPlusSVG />
            </span>
          </div>
          {props.value !== 0 && (
            <div className="Counter__every Counter__value">
              <div>{props.value}</div>
            </div>
          )}
          {props.minValue !== props.value && (
            <div
              onClick={() => decrement()}
              className={classNames("Counter__every Counter__action Counter__decrement", {
                [`Counter__notEmpty`]: props.value > 0,
              })}>
              <span>
                <NewMinusSVG />
              </span>
            </div>
          )}
        </div>
      </>
    );
  };

  return renderMain();
};

Counter.defaultProps = {
  value: 0,
  maxValue: 10,
  minValue: 0,
  onChange: () => {},
};

export default Counter;

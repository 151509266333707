import "./index.scss";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Card from "../../../components/ui/universal/Card";
import { ISignInContent } from "./interface";
import { ReactComponent as ManSVG } from "../../../static/images/svg/new/man.svg";
import { ReactComponent as PhoneSVG } from "../../../static/images/svg/new/phone.svg";
import Input from "../../../components/ui/universal/Input";
import Spacer from "../../../components/ui/universal/Spacer";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";

const SigninContent: React.FC<ISignInContent> = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  const renderMainBlock = () => {
    return (
      <div>
        <Card verticalPadding={3} horizontalPadding={3}>
          <div className="InfoPersonal">
            <div className="InfoPersonal_header">
              <span>Подробнее</span>
            </div>
            <div className="InfoPersonal_box">
              <div className="InfoPersonal_box_item">
                <div className="InfoPersonal_box_item_icon">
                  <ManSVG />
                </div>
                <span>chmo</span>
              </div>
              <div className="InfoPersonal_box_item">
                <div className="InfoPersonal_box_item_icon">
                  <PhoneSVG />
                </div>
                <span>+7 (999) 123-45-87</span>
              </div>
            </div>
            <Spacer size={5} />
            <div className="InfoPersonal_inputBox">
              <Input title={"Должность"} mode="secondary" value={value} onChangeHandler={setValue} />
            </div>
            <div className="InfoPersonal_buttonBox">
              <PrimaryButton text="Уволить" mode={"red"} />
            </div>
          </div>
        </Card>
      </div>
    );
  };

  return renderMainBlock();
};

export default SigninContent;

import "./index.scss";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { commonActions, TYPES } from "../../../store/actions";
import router from "../../../router";
import ItemsCarousel from "react-items-carousel";
import classNames from "classnames";
import IconUniversal from "../../../components/ui/universal/IconUniversal";
import Pagination from "../../../components/common/pagination";
import IsEmptyHandler from "../../../components/common/is-empty-handler";
import { LOADING_STATUS_IN_PROCESS } from "../../../definitions";
import NewRowItem from "../../../components/common/NewRowItem";
import BasketRowItem from "../../../components/common/BasketRowItem";
import Padding from "../../../components/ui/universal/Padding";
import Spacer from "../../../components/ui/universal/Spacer";
import MoreDetailsItem from "../../../components/common/MoreDetailsItem";
import BriefItem from "../../../components/common/BriefItem";
import Heading from "../../../components/ui/universal/Heading";
import { ReactComponent as NoteSVG } from "../../../static/images/svg/new/add_note.svg";
import { ReactComponent as LocSVG } from "../../../static/images/svg/new/location.svg";
import { ReactComponent as PhoneSVG } from "../../../static/images/svg/new/phone.svg";
import { ReactComponent as PlusSVG } from "../../../static/images/svg/new/newPlus.svg";
import { declOfNum } from "../../../utils/declOfNum";
import { getObjectByIdFromArray } from "../../../utils";
import Loading from "../../../components/ui/universal/Loading";
import { ReactComponent as PointCategoriesSVG } from "../../../static/images/svg/new/point_categories.svg";
import { ReactComponent as VkSVG } from "../../../static/images/svg/social/vk.svg";
import { ReactComponent as WhatsappSVG } from "../../../static/images/svg/social/whatsapp.svg";
import { ReactComponent as TelegramSVG } from "../../../static/images/svg/social/telegram.svg";
import * as Modals from "../../../components/ui/universal/Modals/";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import { ReactComponent as DeleteSVG } from "../../../static/images/svg/new/trash.svg";
import { confirmAlert } from "react-confirm-alert";
import { useIsLogged } from "../../../services/api/LoggedContext";
import { PAGE_ROUTES } from "../../../definitions";
import SimpleCheckbox from "../../../components/ui/universal/SimpleCheckbox";
import Input from "../../../components/ui/universal/Input";
import cogoToast from "cogo-toast";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import SuccessOrder from "./SuccessOrder";

const appendCursor = 20;

const NewPointPage = () => {
  const dispatch = useDispatch();
  const { params: routeParams } = router.getState();
  const { id: pointId } = routeParams;
  const { account } = useSelector((store) => ({
    account: store.users.account,
  }));

  const isLogged = useIsLogged();
  let rules, maxLevel;

  console.log(333, isLogged);

  if (isLogged) {
    // rules = account.rules;
    // maxLevel = rules.maxLevel;
    maxLevel = 8;
  }

  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [hiddenInfo, setHiddenInfo] = useState(true);
  const [isVisibleBasket, setIsVisibleBasket] = useState(false);
  const [selectPay, setSelectPay] = useState(false);
  const [selectReceiveOrder, setSelectReceiveOrder] = useState(false);
  const [confirmationCall, setConfirmationCall] = useState(false);
  const [basket, setBasket] = useState({
    data: new Map(),
    force: 0,
  });
  const [basketOpened, setBasketOpened] = useState(false);
  const [successOrder, setSuccessOrder] = useState(false);
  const [forceDelete, setForceDelete] = useState(0);
  const [isSelectedBasketAddresses, setIsSelectedBasketAddresses] = useState(0);
  const [changeNeeded, setChangeNeeded] = useState(0);
  const [counterClick, setCounterClick] = useState(0);

  const { addresses, activeCategory, selectedVariations, selectedAdditions, variationsById, additionsById } =
    useSelector((state) => ({
      addresses: state.common.addresses,
      activeCategory: state.common.activePointCategoryId,
      selectedAdditions: state.common.selectedAdditions,
      selectedVariations: state.common.selectedVariations,
      variationsById: state.common.variationsById,
      additionsById: state.common.additionsById,
    }));

  const updateSubjects = () => {
    dispatch(commonActions.getAddresses());
  };

  const setActiveCategory = (val) => {
    dispatch(commonActions.setActivePointCategoryId(val));
  };

  useEffect(() => {
    if (isLogged) {
      dispatch(commonActions.getAddresses());
    }

    return () => {
      dispatch({ type: TYPES.COMMON.CLEAR_SELECTED_ADDITIONS });
      dispatch({ type: TYPES.COMMON.CLEAR_SELECTED_VARIATIONS });
      setActiveCategory(null);
    };
  }, []);

  useEffect(() => {
    if (basket.data.size === 0) {
      setBasketOpened(false);
    }
  }, [basket.data.size]);

  const basketEdit = ({ data, counter, id }) => {
    const _basket = basket.data;
    if (counter > 0) {
      _basket.set(id, {
        counter,
        data,
      });
    } else {
      _basket.delete(id);
      dispatch({
        type: TYPES.COMMON.REMOVE_SELECTED_ADDITIONS_BY_ID,
        payload: { id },
      });
      dispatch({
        type: TYPES.COMMON.REMOVE_SELECTED_VARIATIONS_BY_ID,
        payload: { id },
      });
    }

    setBasket({
      ...basket,
      data: _basket,
    });

    if (!basketOpened) {
      if (_basket.size > 0) {
        setBasketOpened(true);
      }
    } else if (!!basketOpened && !_basket.size) {
      setBasketOpened(false);
    }
  };

  const basketController = {
    commonOrderPrice: 0,
    allSubjectsCounter: 0,
    rowOnChangeHandler(data) {
      basketEdit({ ...data });
    },
  };

  const { categories, pointsById, subjectsByCategory, loadingStatus } = useSelector((store) => ({
    city: store.common.city,
    categories: store.common.categories,
    tags: store.common.tags,
    points: store.common.points,
    pointsById: store.common.pointsById,
    subjectsByCategory: store.common.subjectsByCategory,
    loadingStatus: store.common.loadingStatus,
  }));

  const getAverageTime = () => {
    if (pointInfo.average_lead_time_on_day > 0) {
      return declOfNum(pointInfo.average_lead_time_on_day, ["день", "дня", "дней"]);
    } else if (pointInfo.average_lead_time_on_hours > 0) {
      return declOfNum(pointInfo.average_lead_time_on_hours, ["час", "часа", "часов"]);
    } else {
      return declOfNum(pointInfo.average_lead_time_on_minutes, ["минута", "минуты", "минут"]);
    }
  };

  useEffect(() => {
    if (!pointsById.hasOwnProperty(pointId)) {
      dispatch(
        commonActions.getPoints({
          id: pointId,
        }),
      );
    }

    const getCategories = dispatch(
      commonActions.getCategories({
        point_id: pointId,
      }),
    );

    getCategories.then((data) => {
      setActiveCategory(
        Object.values(data.data.categories).sort((a, b) => {
          if (a.priority > b.priority) {
            return -1;
          } else {
            return 1;
          }
        })[0].id,
      );
    });
  }, [pointId]);

  useEffect(() => {
    if (activeCategory !== null && !subjectsByCategory.hasOwnProperty(activeCategory)) {
      dispatch(
        commonActions.getSubjects({
          category_id: activeCategory,
          extended: 1,
        }),
      );
    }
  }, [activeCategory]);

  useEffect(() => {
    if (counterClick === 5) {
      const get = dispatch(
        commonActions.getSubjects({
          category_id: activeCategory,
          extended: 1,
        }),
      );
      get.then(() => cogoToast.success("Ваша завка успешно отправлена!"));
    }
  }, [counterClick]);

  const subjects = {
    append: () => {
      dispatch(
        commonActions.getSubjects(
          {
            category_id: activeCategory,
            cursor: subjectsByCategory[activeCategory].hasNext.cursor + appendCursor,
            extended: 1,
          },
          true,
        ),
      );
    },
  };

  if (!pointsById.hasOwnProperty(pointId) || !categories.hasOwnProperty(pointId) || !activeCategory) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const pointInfo = pointsById[pointId];
  const reductionDescription = pointInfo.description.length > 100 && pointInfo.description.substr(0, 100) + "...";

  const sortable = (a, b) => {
    const categoryA = categories[pointId][a];
    const categoryB = categories[pointId][b];
    if (categoryA.priority > categoryB.priority) {
      return -1;
    } else {
      return 1;
    }
  };

  const initialFormikState = {
    comment: "",
    contact_info_id: 0,
    point_id: pointId,
    pay_method_id: 1,
    not_call: 1,
    take_it_myself: 1,
    change_needed: 0,
    items: "",
  };

  const formikValidationSchema = Yup.object().shape({
    comment: Yup.string().max(30, "Не более 30 символов"),
  });

  const renderTabs = () => {
    const chevronWidth = 40;
    return (
      <div className="NewPoint_categories no_select">
        <div className="NewPoint_categories_box">
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={3}
            gutter={3}
            leftChevron={
              <button className="NewPoint_cursorLeft">
                <IconUniversal typeIcon="newLeft" />
              </button>
            }
            rightChevron={
              <button className="NewPoint_cursorRight">
                <IconUniversal typeIcon="newRight" />
              </button>
            }
            outsideChevron={false}
            chevronWidth={chevronWidth}>
            {Object.keys(categories[pointId])
              .sort(sortable)
              .map((_category) => {
                const category = categories[pointId][_category];
                return (
                  <div
                    key={"NewPoint_categories_item_" + category.id}
                    className={classNames("NewPoint_categories_item", {
                      active: category.id === activeCategory,
                    })}
                    onClick={() => setActiveCategory(category.id)}>
                    <span>{category.title.toLocaleUpperCase()}</span>
                  </div>
                );
              })}
          </ItemsCarousel>
        </div>
        <div
          className="PointInfo_box_brief_categories"
          onClick={() => {
            Modals.open("pointCategories", {}, {}, {});
          }}>
          <PointCategoriesSVG />
        </div>
      </div>
    );
  };

  const renderAddresses = () => {
    if (!isLogged) return <></>;
    return (
      <div>
        {Object.keys(addresses.addresses).map((keyItem, key) => {
          const item = addresses.addresses[keyItem];
          return renderAddressesItem({ item, key });
        })}
      </div>
    );
  };

  const renderAddressesItem = ({ item }) => {
    const { name, address, id: address_id } = item;
    if (!isLogged) return <></>;
    return (
      <div className="InfoOrders_item">
        <div className="InfoOrders_item_info">
          <div className="InfoOrders_item_info_name">
            <span>{name}</span>
          </div>
          <div className="InfoOrders_item_info_text">
            <span>{address}</span>
          </div>
        </div>
        <div className="InfoOrders_item_box">
          <SimpleCheckbox
            checked={isSelectedBasketAddresses === address_id}
            onClick={() => {
              setIsSelectedBasketAddresses(address_id);
            }}
          />
        </div>
      </div>
    );
  };

  let basketFinallyPrice = 0;
  let formikOrderItems = [];

  const renderBasketRowsLogic = () => {
    basketFinallyPrice = 0;
    formikOrderItems = [];
    return [...basket.data.keys()].map((key, i) => {
      const buildedOrderItem = {
        subject_id: -1,
        variation_id: 0,
        count: 0,
        additions: [],
      };

      const { counter, data: item } = basket.data.get(key);
      buildedOrderItem.subject_id = item.id;
      buildedOrderItem.count = counter;

      let surchargeVariation = 0;
      if (selectedVariations.has(item.id)) {
        const currentSelectedVariations = selectedVariations.get(item.id);
        const currentVariation = variationsById[item.id];
        if (!!currentVariation.length) {
          buildedOrderItem.variation_id = currentVariation[0].id;
        }
        surchargeVariation =
          surchargeVariation + getObjectByIdFromArray(currentVariation, currentSelectedVariations)[0].price;
      }

      let surchargeAdditions = 0;
      if (selectedAdditions.has(item.id)) {
        const currentSelectedAdditions = selectedAdditions.get(item.id);
        const currentAdditions = additionsById[item.id];
        [...currentSelectedAdditions.keys()].forEach((_key) => {
          const { id: __id, count: __count } = currentSelectedAdditions.get(_key);
          if (!buildedOrderItem.additions.filter(({ id }) => id === __id).length) {
            buildedOrderItem.additions.push({
              id: __id,
              count: __count,
            });
          }
          const { price: additionPrice } = getObjectByIdFromArray(currentAdditions, __id)[0];
          if (additionPrice !== 0) {
            surchargeAdditions = surchargeAdditions + __count * additionPrice;
          }
        });
      }

      basketFinallyPrice = basketFinallyPrice + counter * (item.price + surchargeVariation + surchargeAdditions);

      if (!formikOrderItems.filter(({ subject_id }) => subject_id === buildedOrderItem.subject_id).length) {
        formikOrderItems.push(buildedOrderItem);
      }

      return (
        <div key={"BasketRowItem" + item.id}>
          <BasketRowItem
            {...item}
            price={item.price + surchargeVariation + surchargeAdditions}
            originalPrice={item.price}
            force={forceDelete}
            photo={item.hasOwnProperty("photo1") ? item.photo1["150"] : ""}
            counter={counter}
            preDefaultCounter={counter}
            onChange={(item) => {
              basketController.rowOnChangeHandler(item);
            }}
            noEdit
          />
        </div>
      );
    });
  };

  const renderOrders = () => {
    if (!isLogged) return <></>;
    return (
      <div>
        <Formik
          initialValues={initialFormikState}
          validationSchema={formikValidationSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const valuesClone = values;
            valuesClone.point_id = +pointId;
            if (isSelectedBasketAddresses === 0) {
              delete valuesClone.contact_info_id;
            } else {
              valuesClone.contact_info_id = isSelectedBasketAddresses;
            }
            valuesClone.take_it_myself = +selectReceiveOrder;
            valuesClone.pay_method_id = +selectPay;
            valuesClone.not_call = +confirmationCall;
            valuesClone.change_needed = +changeNeeded;
            valuesClone.items = JSON.stringify(formikOrderItems);
            const addOrder = dispatch(commonActions.addOrder(values));
            addOrder.then(
              (data) => {
                setSubmitting(true);
                setSuccessOrder(!successOrder);
                cogoToast.success("Заказ отправлен!");
              },
              ({ errors }) => {
                setSubmitting(false);
                cogoToast.error("Ошибка!");
              },
            );
          }}
          render={({ errors, touched, values, setFieldValue }) => {
            return (
              <Form>
                {successOrder && <SuccessOrder />}
                <div className="RenderOrder">
                  <div className="RenderOrder_close" onClick={() => setIsVisibleBasket(false)}>
                    <div className="RenderOrder_close_icon" />
                  </div>
                  <div className="RenderOrder_content">
                    <Padding vertical={1} horizontal={3}>
                      <div className="RenderOrder_content_title">
                        <span>Детали заказа</span>
                        <div
                          className="RenderOrder_content_title_delete"
                          onClick={() => {
                            confirmAlert({
                              title: "Удалить заказ из корзины?",
                              buttons: [
                                {
                                  label: "Да",
                                  onClick: () => {
                                    setBasket({
                                      ...basket,
                                      data: new Map(),
                                      additions: new Map(),
                                      variations: new Map(),
                                    });
                                    dispatch({
                                      type: TYPES.COMMON.CLEAR_SELECTED_ADDITIONS,
                                    });
                                    dispatch({
                                      type: TYPES.COMMON.CLEAR_SELECTED_VARIATIONS,
                                    });
                                    setIsVisibleBasket(false);
                                    setBasketOpened(false);
                                    setForceDelete(forceDelete + 1);
                                  },
                                },
                                {
                                  label: "Нет",
                                  onClick: () => {},
                                },
                              ],
                            });
                          }}>
                          <DeleteSVG />
                        </div>
                      </div>

                      {renderBasketRowsLogic()}

                      <div className="InfoOrders_title">
                        <span>Способ получения</span>
                      </div>
                      <div className="InfoOrders_box">
                        <div className="InfoOrders_item">
                          <div className="InfoOrders_item_info">
                            <span>Доставка</span>
                          </div>
                          <div className="InfoOrders_item_box">
                            <SimpleCheckbox
                              checked={+selectReceiveOrder === 0 && true}
                              onClick={() => {
                                setSelectReceiveOrder(!selectReceiveOrder);
                              }}
                            />
                          </div>
                        </div>
                        <div className="InfoOrders_item">
                          <div className="InfoOrders_item_info">
                            <span>Самовывоз</span>
                          </div>
                          <div className="InfoOrders_item_box">
                            <SimpleCheckbox
                              checked={+selectReceiveOrder === 1 && true}
                              onClick={() => {
                                setSelectReceiveOrder(!selectReceiveOrder);
                                setIsSelectedBasketAddresses(0);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="InfoOrders">
                          {!selectReceiveOrder && (
                            <Padding vertical={2} horizontal={0}>
                              <div>
                                <div className="InfoOrders_title">
                                  <span>Выберите адрес доставки</span>
                                </div>

                                <div className="InfoOrders_box">
                                  {!!Object.keys(addresses.addresses).length && <div>{renderAddresses()}</div>}
                                  <div
                                    className="InfoOrders_item"
                                    onClick={() => {
                                      Modals.open(
                                        "addAddress",
                                        {},
                                        {},
                                        {
                                          onCloseHandler: () => {
                                            updateSubjects();
                                          },
                                        },
                                      );
                                    }}>
                                    <div className="InfoOrders_item_left">
                                      <div className="InfoOrders_item_left_decor">
                                        <PlusSVG />
                                      </div>
                                      <span>Добавьте адрес</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Padding>
                          )}

                          <div className="InfoOrders_title">
                            <span>Способ оплаты</span>
                          </div>
                          <div className="InfoOrders_box">
                            <div className="InfoOrders_item">
                              <div className="InfoOrders_item_info">
                                <span>При получении, наличными</span>
                              </div>
                              <div className="InfoOrders_item_box">
                                <SimpleCheckbox
                                  checked={+selectPay === 0 && true}
                                  onClick={() => {
                                    setSelectPay(!selectPay);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="InfoOrders_item">
                              <div className="InfoOrders_item_info">
                                <span>Картой</span>
                              </div>
                              <div className="InfoOrders_item_box">
                                <SimpleCheckbox
                                  checked={+selectPay === 1 && true}
                                  onClick={() => {
                                    setSelectPay(!selectPay);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          {!selectPay && (
                            <div>
                              <Padding vertical={2} horizontal={0}>
                                <div className="InfoOrders_changeNeeded">
                                  <div className="InfoOrders_changeNeeded_header">
                                    <span>Нужна сдача с</span>
                                  </div>
                                  <div className="InfoOrders_changeNeeded_section">
                                    <div
                                      className="InfoOrders_changeNeeded_section_centred"
                                      onClick={() => {
                                        setChangeNeeded(0);
                                      }}>
                                      <BriefItem
                                        style={{
                                          width: "100%",
                                          height: "50px",
                                          fontSize: "17px",
                                        }}
                                        mode={changeNeeded === 0 && "primary"}
                                        text="Нет"
                                      />
                                    </div>
                                    <div
                                      className="InfoOrders_changeNeeded_section_centred"
                                      onClick={() => {
                                        setChangeNeeded(1000);
                                      }}>
                                      <BriefItem
                                        style={{
                                          width: "100%",
                                          height: "50px",
                                          fontSize: "17px",
                                        }}
                                        mode={changeNeeded === 1000 && "primary"}
                                        text="1000"
                                      />
                                    </div>
                                  </div>
                                  <Spacer size={5} />
                                  <div className="InfoOrders_changeNeeded_section">
                                    <div
                                      className="InfoOrders_changeNeeded_section_centred"
                                      onClick={() => {
                                        setChangeNeeded(2000);
                                      }}>
                                      <BriefItem
                                        style={{
                                          width: "100%",
                                          height: "50px",
                                          fontSize: "17px",
                                        }}
                                        mode={changeNeeded === 2000 && "primary"}
                                        text="2000"
                                      />
                                    </div>
                                    <div
                                      className="InfoOrders_changeNeeded_section_centred"
                                      onClick={() => {
                                        setChangeNeeded(5000);
                                      }}>
                                      <BriefItem
                                        style={{
                                          width: "100%",
                                          height: "50px",
                                          fontSize: "17px",
                                        }}
                                        mode={changeNeeded === 5000 && "primary"}
                                        text="5000"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Padding>
                            </div>
                          )}

                          <div className="InfoOrders_title">
                            <span>Позвонить для уточнения заказа</span>
                          </div>
                          <div className="InfoOrders_box">
                            <div className="InfoOrders_item">
                              <div className="InfoOrders_item_info">
                                <span>Позвонить</span>
                              </div>
                              <div className="InfoOrders_item_box">
                                <SimpleCheckbox
                                  checked={+confirmationCall === 1 && true}
                                  onClick={() => {
                                    setConfirmationCall(!confirmationCall);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="InfoOrders_item">
                              <div className="InfoOrders_item_info">
                                <span>По возможности не звонить</span>
                              </div>
                              <div className="InfoOrders_item_box">
                                <SimpleCheckbox
                                  checked={+confirmationCall === 0 && true}
                                  onClick={() => {
                                    setConfirmationCall(!confirmationCall);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="InfoOrders_title">
                            <span>Детали</span>
                          </div>
                          <div className="InfoOrders_box">
                            <div className="InfoOrders_item">
                              <div className="InfoOrders_item_info">
                                <span>Время доставки</span>
                              </div>
                              <div className="InfoOrders_item_box">
                                <span>{getAverageTime()}</span>
                              </div>
                            </div>

                            <div className="InfoOrders_item">
                              <div className="InfoOrders_item_info">
                                <span>Минимальная сумма заказа</span>
                              </div>
                              <div className="InfoOrders_item_box">
                                <span>{pointInfo.minimal_order} ₽</span>
                              </div>
                            </div>

                            <div className="InfoOrders_item">
                              <div className="InfoOrders_item_info">
                                <span>Доставка заказа</span>
                              </div>
                              <div className="InfoOrders_item_box">
                                <span>{pointInfo.delivery_price} ₽</span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="InfoOrders_title">
                              <span>Другое</span>
                            </div>
                            <Padding horizontal={0} vertical={2}>
                              {/*<div>*/}
                              {/*  <Input*/}
                              {/*    title={'Промокод'}*/}
                              {/*    mode="secondary"*/}
                              {/*    placeholder={'Введите промокод'}*/}
                              {/*    name="title"*/}
                              {/*    value={values.title}*/}
                              {/*    onChangeHandler={(e) => setFieldValue('title', e)}*/}
                              {/*    error={!!(errors.title && touched.title)}*/}
                              {/*    description={(errors.title && touched.title) && (*/}
                              {/*      <ErrorMessage name="title" component="div" className="invalid-feedback" />*/}
                              {/*    )}*/}
                              {/*  />*/}
                              {/*</div>*/}
                              {/*<Spacer size={10}/>*/}
                              <div>
                                <Input
                                  maxLength={30}
                                  title={"Комментарий"}
                                  mode="secondary"
                                  placeholder={"Оставьте комментарий к заказу"}
                                  name="title"
                                  value={values.comment}
                                  onChangeHandler={(e) => setFieldValue("comment", e)}
                                  error={!!(errors.comment && touched.comment)}
                                  description={
                                    errors.comment &&
                                    touched.comment && (
                                      <ErrorMessage name="comment" component="div" className="invalid-feedback" />
                                    )
                                  }
                                />
                              </div>
                            </Padding>
                          </div>
                        </div>
                      </div>
                    </Padding>
                    <div className="RenderOrder_content_basket">
                      <div className="RenderOrder_content_basket_centred">
                        <PrimaryButton
                          price={basketFinallyPrice}
                          style={{
                            fontWeight: "bold",
                            minHeight: "48px",
                          }}
                          text="Заказать"
                          mode="primary"
                          btnType="submit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <div
        className="Point_cover"
        style={{
          backgroundImage: pointInfo.hasOwnProperty("cover") ? `url(${pointInfo.cover["900"]})` : "",
        }}>
        <div
          className="Point_cover_logotype"
          style={{
            backgroundImage: pointInfo.hasOwnProperty("logotype") ? `url(${pointInfo.logotype["450"]})` : "",
          }}
        />
      </div>
      <Padding horizontal={3} vertical={2}>
        <Heading size={2} underline={false}>
          <div className="PointInfo">
            <div className="PointInfo_box">
              <div
                className="PointInfo_box_name"
                onClick={() => {
                  setCounterClick(counterClick + 1);
                }}>
                <Heading size={1} underline={1}>
                  {pointInfo.title}
                </Heading>
              </div>

              {isLogged && maxLevel >= 4 && (
                <div
                  className="PointInfo_box_edit"
                  onClick={() => {
                    router.navigate(PAGE_ROUTES.EDIT_POINT, {
                      id: pointId,
                    });
                  }}>
                  <span>Редактировать точку</span>
                </div>
              )}

              <div className="PointInfo_box_brief">
                <BriefItem text={getAverageTime()} />

                <BriefItem text={`${pointInfo.average_check}₽`} />
              </div>
              <div className="PointInfo_box_description">
                <div className="PointInfo_box_description_name">
                  <span>Описание</span>
                </div>
                <div className="PointInfo_box_description_text">
                  <span>{hiddenInfo ? reductionDescription : pointInfo.description}</span>
                </div>
              </div>
            </div>
            {hiddenInfo && (
              <div className="PointInfo_details" onClick={() => setHiddenInfo(!hiddenInfo)}>
                <span>Подробнее о ресторане</span>
              </div>
            )}
            {!hiddenInfo && (
              <div className="PointInfo_more">
                <Spacer size={8} />
                <div className="PointInfo_more_info">
                  <span>Средний чек</span>
                </div>
                <div className="PointInfo_more_text">
                  <span>от {pointInfo.average_check}₽</span>
                </div>
                <Spacer size={2} />
                <div className="PointInfo_more_info">
                  <span>Бесплатная доставка</span>
                </div>
                <div className="PointInfo_more_text">
                  <span>от {pointInfo.delivery_free_from}₽</span>
                </div>
                <Spacer size={2} />
                <div className="PointInfo_more_info">
                  <span>Минимальный заказ</span>
                </div>
                <div className="PointInfo_more_text">
                  <span>от {pointInfo.minimal_order}₽</span>
                </div>

                <Spacer size={2} />
                <MoreDetailsItem
                  title="Сейчас"
                  description={pointInfo.opened ? "открыто " : "закрыто "}
                  icon={<NoteSVG />}
                />

                <MoreDetailsItem title={"Адрес"} description={pointInfo.addresses} icon={<LocSVG />} />

                <MoreDetailsItem title={"Телефон"} description={pointInfo.phones} icon={<PhoneSVG />} />

                <Spacer size={2} />

                {(!!pointInfo.vk_link.length ||
                  !!pointInfo.whatsapp_link.length ||
                  !!pointInfo.telegram_link.length) && (
                  <div className="PointInfo_more_links">
                    <div className="PointInfo_more_links_header">
                      <div className="PointInfo_more_links_header_text">Контакты</div>
                    </div>
                    <div className="PointInfo_more_links_item">
                      {!!pointInfo.vk_link.length && (
                        <div className="PointInfo_more_links_item_icon">
                          <a href={pointInfo.vk_link}>
                            <VkSVG />
                          </a>
                        </div>
                      )}
                      {!!pointInfo.whatsapp_link.length && (
                        <div className="PointInfo_more_links_item_icon">
                          <a href={`whatsapp://send/?phone=${pointInfo.whatsapp_link}`}>
                            <WhatsappSVG />
                          </a>
                        </div>
                      )}
                      {!!pointInfo.telegram_link.length && (
                        <div className="PointInfo_more_links_item_icon">
                          <a href={`tg://resolve?domain=${pointInfo.telegram_link}`}>
                            <TelegramSVG />
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <Spacer size={5} />

                <div className="PointInfo_more_legal">
                  <div className="PointInfo_more_legal_header">
                    <span>Легальная информация</span>
                  </div>
                  <div className="PointInfo_more_legal_info">
                    <span>{pointInfo.legal_information}</span>
                  </div>
                </div>
              </div>
            )}
            {!hiddenInfo && (
              <div className="PointInfo_details" onClick={() => setHiddenInfo(!hiddenInfo)}>
                <span>Свернуть</span>
              </div>
            )}
          </div>
        </Heading>
      </Padding>

      <div>{renderTabs()}</div>

      <div>
        {subjectsByCategory.hasOwnProperty(activeCategory) &&
          (() => {
            return (
              <IsEmptyHandler
                counter={subjectsByCategory[activeCategory].countAll}
                showCounter={true}
                counterText={categories[pointId][activeCategory].title}
                placeholderText="Здесь пусто :)">
                <Pagination
                  hasNext={subjectsByCategory[activeCategory].hasNext.hasNext}
                  loadingStatus={loadingStatus["subjectsIsLoading"] === LOADING_STATUS_IN_PROCESS}
                  loadingMore={subjects.append}>
                  <Padding horizontal={1}>
                    {subjectsByCategory[activeCategory].rows.map((item) => {
                      let surcharge = 0;
                      if (selectedVariations.has(item.id)) {
                        const currentSelectedVariations = selectedVariations.get(item.id);
                        const currentVariation = variationsById[item.id];
                        surcharge =
                          surcharge + getObjectByIdFromArray(currentVariation, currentSelectedVariations)[0].price;
                      }

                      let surchargeAdditions = 0;
                      if (selectedAdditions.has(item.id)) {
                        const currentSelectedAdditions = selectedAdditions.get(item.id);
                        const currentAdditions = additionsById[item.id];
                        [...currentSelectedAdditions.keys()].forEach((_key) => {
                          const { id: __id, count: __count } = currentSelectedAdditions.get(_key);
                          const { price: additionPrice } = getObjectByIdFromArray(currentAdditions, __id)[0];
                          if (additionPrice !== 0) {
                            surchargeAdditions = surchargeAdditions + __count * additionPrice;
                          }
                        });
                      }

                      const currentCounter = basket.data.get(item.id)?.counter;
                      const optionalProps = {};
                      if (!!currentCounter) {
                        optionalProps.preDefaultCounter = currentCounter;
                      }

                      return (
                        <NewRowItem
                          key={"NewRowItem_" + item.id}
                          {...item}
                          price={item.price + surcharge + surchargeAdditions}
                          force={forceDelete}
                          photo={item.hasOwnProperty("photo1") ? item.photo1["150"] : ""}
                          onChange={(item) => {
                            basketController.rowOnChangeHandler(item);
                          }}
                          setForceDelete={setForceDelete}
                          {...optionalProps}
                        />
                      );
                    })}
                  </Padding>
                </Pagination>
              </IsEmptyHandler>
            );
          })()}
      </div>

      {isLogged && !!basketOpened && !isVisibleBasket && (
        <div>
          <div className="NewBasket">
            <div className="NewBasket_fake">
              <div className="NewBasket_main">
                <div className="NewBasket_main_left" onClick={() => setIsVisibleBasket(true)}>
                  <span>Продолжить</span>
                  {/*{_getBasket.counter > 0 && (*/}
                  {/* <span>*/}
                  {/*  {_getBasket.price} ₽*/}
                  {/* </span>*/}
                  {/*)}*/}
                </div>
                {!isVisibleBasket && (
                  <div className="NewBasket_main_right">
                    <PrimaryButton
                      style={{
                        fontWeight: "bold",
                        color: "black",
                        minHeight: "48px",
                      }}
                      text="Очистить"
                      onClick={() => {
                        confirmAlert({
                          title: "Очистить корзину?",
                          buttons: [
                            {
                              label: "Да",
                              onClick: () => {
                                setBasket({
                                  ...basket,
                                  data: new Map(),
                                  additions: new Map(),
                                  variations: new Map(),
                                });
                                dispatch({
                                  type: TYPES.COMMON.CLEAR_SELECTED_ADDITIONS,
                                });
                                dispatch({
                                  type: TYPES.COMMON.CLEAR_SELECTED_VARIATIONS,
                                });
                                setForceDelete(forceDelete + 1);
                              },
                            },
                            {
                              label: "Нет",
                              onClick: () => {},
                            },
                          ],
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {isLogged && isVisibleBasket && <div>{renderOrders()}</div>}
    </div>
  );
};

export default NewPointPage;

import React, { useContext, useEffect, useState } from "react";
import { useAuthClearAccessToken, useAuthUserId } from "./AuthContext";
import { useApiRequest } from "./APIProvider";
import router from "../../router";
import { PAGE_ROUTES } from "../../definitions";

export const LoggedContext = React.createContext({});
export const useLoggedContext = () => useContext(LoggedContext);
export const useIsLogged = () => !!useLoggedContext().user;
export const LoggedContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const userId = useAuthUserId();
  const doRequest = useApiRequest();
  const clearAccessToken = useAuthClearAccessToken();
  useEffect(() => {
    if (userId && userId > 0) {
      doRequest("users.getMe")
        .then((userData) => {
          setUser(userData);
        })
        .catch(() => {
          doRequest("auth.logout", {}).then(() => {
            clearAccessToken();
            router.navigate(PAGE_ROUTES.MAIN);
            //window.reload();
          });
        });
    }
  }, []);
  const params = {
    user,
  };
  return <LoggedContext.Provider value={params}>{children}</LoggedContext.Provider>;
};

import "./index.scss";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spacer from "../../../components/ui/universal/Spacer";
import { commonActions, usersActions } from "../../../store/actions";
import MobileHeader from "../../../components/ui/mobile/MobileHeader";
import HeaderTitle from "../../../components/ui/mobile/HeaderTitle";
import classNames from "classnames";
import { useLocalStorage } from "react-use";
import cogoToast from "cogo-toast";
import { useIsLogged } from "../../../services/api/LoggedContext";
import { ReactComponent as AddSVG } from "../../../static/images/svg/new/left_chevron.svg";
import { ReactComponent as RightSVG } from "../../../static/images/svg/new/right_check.svg";
import { PAGE_ROUTES } from "../../../definitions/routes";
import router5 from "../../../router";
import Loading from "../../../components/ui/universal/Loading";

const Index = (props) => {
  const dispatch = useDispatch();
  const [lsCity, setLsCity] = useLocalStorage("city", null);
  const lsItem = localStorage.getItem("city");
  const lsScCity = localStorage.getItem("sc");
  const [state, setState] = useState({
    selectedCity:
      lsItem !== null
        ? JSON.parse(lsItem)
        : {
            id: 1347,
            name: "Петушки",
          },
  });

  const isLogged = useIsLogged();

  const { common } = useSelector((store) => ({
    common: store.common,
  }));

  useEffect(() => {
    if (!common.cities.length) {
      const getCities = dispatch(commonActions.getCities());
      getCities.then(
        (data) => {},
        (status) => {},
      );
    }
  }, []);

  useEffect(() => {
    if (common.cities instanceof Array) {
      if (!!common.cities.length) {
        if (lsItem === null) {
          if (lsScCity !== null) {
            const sc = common.cities.filter((cities) => {
              return cities.id === +lsScCity;
            });
            if (sc.length) {
              setState({
                ...state,
                selectedCity: {
                  id: sc[0].id,
                  name: sc[0].name,
                },
              });

              setLsCity({
                id: sc[0].id,
                name: sc[0].name,
              });
              window.location.href = "/";
            }
          }
        }
      }
    }
  }, [common.cities]);

  if (!common.cities.length) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const citySaveHandler = ({ id, name }, cb = undefined) => {
    setState({
      ...state,
      selectedCity: { id, name },
    });

    setLsCity({ id, name });

    if (isLogged) {
      const setCity = dispatch(usersActions.setCity(state.selectedCity.id));
      setCity.then(
        () => {
          cogoToast.success("Сохранено!");
          if (cb) cb();
          window.location.href = "/";
        },
        () => {
          cogoToast.error("Ошибка");
        },
      );
    } else {
      if (cb) cb();
      window.location.href = "/";
    }
  };

  const cityItem = (item) => {
    const { name, id } = item;
    return (
      <div
        className="CitiesPage_map_item"
        onClick={() => {
          citySaveHandler({ id, name });
        }}>
        <span
          className={classNames({
            active: state.selectedCity.id === id,
          })}>
          {item.name}
        </span>
        {state.selectedCity.id === id && (
          <span className="CitiesPage_map_item_icon_check">
            <RightSVG />
          </span>
        )}
      </div>
    );
  };

  const citiesMapping = () => {
    return (
      <div>
        {common.cities.map((item) => {
          return <div key={item.id}>{cityItem(item)}</div>;
        })}
      </div>
    );
  };

  return (
    <div className="CitiesPage">
      <div>
        <MobileHeader
          left={
            lsItem === null ? (
              <div />
            ) : (
              <div
                className="CitiesPage_header_icon"
                onClick={() => {
                  router5.navigate(PAGE_ROUTES.MAIN);
                }}>
                <AddSVG />
              </div>
            )
          }
          right={<div />}>
          <HeaderTitle text="Выберите город" align="center" />
        </MobileHeader>
      </div>

      <Spacer size={4} />

      <div className="CitiesPage_map_prompt_text">Выберите город, в котором планируете искать услуги.</div>

      <Spacer size={4} />

      <div className="CitiesPage_map">{citiesMapping()}</div>

      <Spacer size={4} />

      <div className="CitiesPage_fixedBottom_fake" />
    </div>
  );
};

export default Index;

export default class Cache {

    /**
     * Cache objects
     *
     * @type {{}}
     */
    objects = {};

    /**
     * @param cacheName
     *
     * @returns {boolean}
     */
    exists = (cacheName) => this.objects.hasOwnProperty(cacheName);

    /**
     * @param cacheName
     *
     * @returns {*}
     */
    get = (cacheName) => this.exists(cacheName) && this.objects[cacheName];

    /**
     * @param cacheName
     * @param data
     *
     * @returns {*}
     */
    set = (cacheName, data) => {
        this.objects[cacheName] = data;
    };


    /**
     * @param args
     * @returns {string}
     */
    cacheName = (...args) => JSON.stringify(args);
}

import React from "react";
import RowListItem from "../../../components/common/RowListItem";
import { ReactComponent as ChevronSVG } from "../../../static/images/svg/new/chevronRight.svg";
import router5 from "../../../router";
import { PAGE_ROUTES } from "../../../definitions";
import { ReactComponent as AdminCogSvg } from "../MenuPage/assets/adminsIcons/cog.svg";
import { ReactComponent as AdminWalletSvg } from "../MenuPage/assets/adminsIcons/wallet.svg";
import { ReactComponent as AdminOrdersSvg } from "../MenuPage/assets/adminsIcons/orders.svg";
import { ReactComponent as AdminCategorySvg } from "../MenuPage/assets/adminsIcons/category.svg";
import { ReactComponent as AdminProductSvg } from "../MenuPage/assets/adminsIcons/product.svg";
import { ReactComponent as AdminPersonalSvg } from "../MenuPage/assets/adminsIcons/personal.svg";
import { ReactComponent as HelpSvg } from "../MenuPage/assets/adminsIcons/help.svg";
import { ReactComponent as AdminOrdersStatisticsSvg } from "../MenuPage/assets/adminsIcons/ordersStatistics.svg";
import { ReactComponent as AdminStaticUserSvg } from "../MenuPage/assets/adminsIcons/staticUser.svg";
import { ReactComponent as AdminTopProductSvg } from "../MenuPage/assets/adminsIcons/topProduct.svg";
import { ReactComponent as MailSvg } from "../MenuPage/assets/menuItem/mail.svg";
import { useGetMyPointInfo } from "../../../hooks";
import Loading from "../../../components/ui/universal/Loading";

const SpecialMenuPage = () => {
  const { isLoadedThisPoint, pointId } = useGetMyPointInfo();

  if (!isLoadedThisPoint) {
    return <Loading />;
  }

  const renderSpecialMenu = () => {
    return (
      <>
        <div className="Main__full_width">
          <RowListItem
            title="Общие настройки"
            icon={<AdminCogSvg />}
            chevron={<ChevronSVG />}
            onClick={() => {
              router5.navigate(PAGE_ROUTES.GENERAL_POINT_SETTINGS, {
                id: pointId,
              });
            }}
          />
          <RowListItem
            title="Баланс"
            icon={<AdminWalletSvg />}
            chevron={<ChevronSVG />}
            onClick={() =>
              router5.navigate(PAGE_ROUTES.BALANCE, {
                id: pointId,
              })
            }
          />
          <RowListItem
            title="Заказы"
            icon={<AdminOrdersSvg />}
            chevron={<ChevronSVG />}
            onClick={() =>
              router5.navigate(PAGE_ROUTES.SPECIALORDERS, {
                id: pointId,
              })
            }
          />
          <RowListItem
            title="Категории"
            icon={<AdminCategorySvg />}
            chevron={<ChevronSVG />}
            onClick={() =>
              router5.navigate(PAGE_ROUTES.SPECIALCATEGORIES, {
                id: pointId,
              })
            }
          />
          <RowListItem
            title="Услуги / Товары"
            icon={<AdminProductSvg />}
            chevron={<ChevronSVG />}
            onClick={() =>
              router5.navigate(PAGE_ROUTES.PRODUCTS, {
                id: pointId,
              })
            }
          />
          <RowListItem
            title="Персонал"
            icon={<AdminPersonalSvg />}
            chevron={<ChevronSVG />}
            onClick={() =>
              router5.navigate(PAGE_ROUTES.PERSONAL, {
                id: pointId,
              })
            }
          />
          <RowListItem
            title="Помощь"
            icon={<HelpSvg />}
            chevron={<ChevronSVG />}
            onClick={() => router5.navigate(PAGE_ROUTES.MAIN)}
          />
          <RowListItem
            title="Статистика по заказам"
            disabled={true}
            icon={<AdminOrdersStatisticsSvg />}
            onClick={() => router5.navigate(PAGE_ROUTES.MAIN)}
          />
          <RowListItem
            title="Статистика по клиентам"
            disabled={true}
            icon={<AdminStaticUserSvg />}
            onClick={() => router5.navigate(PAGE_ROUTES.MAIN)}
          />
          <RowListItem
            title="Топ услуг"
            disabled={true}
            icon={<AdminTopProductSvg />}
            onClick={() => router5.navigate(PAGE_ROUTES.MAIN)}
          />
          <RowListItem
            disabled={true}
            title="Написать разработчикам"
            icon={<MailSvg />}
            onClick={() => router5.navigate(PAGE_ROUTES.MAIN)}
          />
          <div className="Main__padding24"></div>
        </div>
      </>
    );
  };

  return <div>{renderSpecialMenu()}</div>;
};

export default SpecialMenuPage;

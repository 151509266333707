import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Cache from './Cache';

export const APIContext = React.createContext({});

export const useApiContext = () => useContext(APIContext);
export const useApiRequest = () => useApiContext().doRequest;

export class APIProvider extends React.PureComponent {

    static defaultProps = {
        method: 'GET',
        headers: {},
        serviceParams: {},
    };

    static propTypes = {
        endpoint: PropTypes.string.isRequired,
        method: PropTypes.string,
        headers: PropTypes.object,
        serviceParams: PropTypes.object,
    };

    componentDidMount() {
        this.cache = new Cache();
    }


    /**
     * @param params
     */
    appendServiceParams = (params) => {
        return {
            ...params,
            ...this.props.serviceParams
        }
    };


    /**
     * @param {string} method
     * @param {object} params
     *
     * @returns {string}
     */
    getEndpoint = (method, params) => `${this.props.endpoint}${method}?${Object.entries(this.appendServiceParams(params)).map(([key, val]) => `${key}=${val}`).join('&')}`;


    /**
     * @returns RequestInit
     */
     get fetchInit() {
         return {
             method: this.props.method,
             mode: 'cors',
             headers: {
                 ...this.props.headers
             }
         };
    }

    /**
     * @param responseValue
     *
     * @returns {boolean}
     */
    isError = (responseValue) => responseValue.hasOwnProperty('errors') && responseValue.length > 0 || responseValue.hasOwnProperty('error') || responseValue.hasOwnProperty('error_msg');

    /**
     * @param method
     * @param params
     * @param {object} opts
     *
     * @return Promise
     */
    doRequest = (method, params = {}, { force = false } = {}) => {
        return new Promise((resolve, reject) => {
            const cacheName = this.cache.cacheName(method, params);
            if (!force && this.cache.exists(cacheName)) {
                const cacheValue = this.cache.get(cacheName);
                if (this.isError(cacheValue)) {
                   return reject(cacheValue);
                }
                return resolve(cacheValue);
            }
            fetch(this.getEndpoint(method, params), this.fetchInit)
                .then((response) => response.json())
                .then((resultValue) => {
                    if (!force) {
                        this.cache.set(cacheName, resultValue);
                    }
                    if (this.isError(resultValue)) {
                        return reject(resultValue);
                    }
                    return resolve(resultValue);
                }).catch((error) => reject({ errors: [
                    {  message: error.message }
                ]}));
        });
    };

    requestContext = {
        doRequest: this.doRequest,
    };


    render() {
        return (
            <APIContext.Provider value={this.requestContext}>
                {this.props.children}
            </APIContext.Provider>
        );
    }

}

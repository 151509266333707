import "./index.scss";
import React, { useState } from "react";
import Padding from "../../../components/ui/universal/Padding";
import Tumbler from "../../../components/ui/universal/Tumbler";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import * as Modals from "../../../components/ui/universal/Modals";

const PersonalPage = () => {
  const [tumblerOn, setTumblerOn] = useState(" ");

  return (
    <div>
      <Padding horizontal={3} vertical={3}>
        <Tumbler
          on={tumblerOn}
          onChange={() => setTumblerOn(!tumblerOn)}
          firstTitle="Сотрудники"
          secondTitle="Заявки"
        />
      </Padding>

      {!tumblerOn && (
        <div
          className="PersonalPage_item"
          onClick={() => {
            Modals.open("infoPersonal", {}, {}, {});
          }}>
          <div className="PersonalPage_item_name">
            <span>Данил Даньшин</span>
          </div>
          <div className="PersonalPage_item_description">
            <div className="PersonalPage_item_description_status">Владелец</div>
            <div className="PersonalPage_item_description_phone">+7 (999) 123-45-87</div>
          </div>
        </div>
      )}

      {!!tumblerOn && (
        <div className="PersonalPage_box">
          <div
            className="PersonalPage_box_info"
            onClick={() => {
              Modals.open("infoPersonal", {}, {}, {});
            }}>
            <div className="PersonalPage_box_info_name">Марина Антоненко</div>
            <div className="PersonalPage_box_info_phone">+7 (999) 123-45-87</div>
          </div>
          <div className="PersonalPage_box_buttonBox">
            <div className="PersonalPage_box_buttonBox_item">
              <PrimaryButton text="Принять" mode={"primary"} />
            </div>
            <div className="PersonalPage_box_buttonBox_item">
              <PrimaryButton text="Отклонить" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalPage;

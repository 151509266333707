import "./index.scss";
import React, { useEffect, useState } from "react";
import Tumbler from "../../../components/ui/universal/Tumbler";
import Padding from "../../../components/ui/universal/Padding";
import Input from "../../../components/ui/universal/Input";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import Cell from "../../../components/ui/mobile/Cell";
import { useGetMyPointInfo } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "../../../store/actions/common-actions";
import Loading from "../../../components/ui/universal/Loading";
import { v4 as uuidv4 } from "uuid";
import { Formik, Form, ErrorMessage } from "formik";
import Spacer from "../../../components/ui/universal/Spacer";
import * as Yup from "yup";
import cogoToast from "cogo-toast";
import * as Modals from "../../../components/ui/universal/Modals";
import { ReactComponent as EditSVG } from "../../../static/images/svg/new/edit.svg";

const REQUIRED_FIELD_ERROR = "Обязательное поле";

const SpecialCategories = () => {
  const dispatch = useDispatch();
  const { pointId } = useGetMyPointInfo();
  const [tumblerOn, setTumblerOn] = useState(" ");
  const [isLoadingQueryUpdate, setIsLoadingQueryUpdate] = useState(false);
  const [isInstalledCategories, setInstalledCategories] = useState(false);
  const [categoriesNeedUpdate, setCategoriesNeedUpdate] = useState(false);
  const [force, setForce] = useState(0);

  const { categories, pointsById } = useSelector((store) => ({
    categories: store.common.categories,
    pointsById: store.common.pointsById,
  }));

  const [draggingList, setDraggingList] = useState([]);
  const isLoadedCategories = !!Object.keys(categories).length;

  useEffect(() => {
    if (!pointsById.hasOwnProperty(pointId)) {
      dispatch(
        commonActions.getPoints({
          id: pointId,
        }),
      );
    }

    dispatch(
      commonActions.getCategories({
        point_id: pointId,
      }),
    );
  }, [pointId]);

  useEffect(() => {
    if (force === 0) return;
    const getCategories = dispatch(
      commonActions.getCategories({
        point_id: pointId,
      }),
    );

    getCategories.then(() => {
      setCategoriesNeedUpdate(true);
    });
  }, [force]);

  const updateSubjects = () => {
    dispatch(
      commonActions.getCategories({
        point_id: pointId,
      }),
    );
  };

  useEffect(() => {
    // если категории загружены но не установлены в драггабле
    if (isLoadedCategories && !isInstalledCategories) {
      setDraggingList(Object.keys(categories[pointId]).map((item) => parseInt(item)));
      setInstalledCategories(true);
    }
  }, [isLoadedCategories]);

  useEffect(() => {
    if (isLoadedCategories && categoriesNeedUpdate) {
      setDraggingList(Object.keys(categories[pointId]).map((item) => parseInt(item)));
      setCategoriesNeedUpdate(false);
    }
  }, [categoriesNeedUpdate]);

  if (!isLoadedCategories) {
    return <Loading />;
  }

  const sortable = (a, b) => {
    const categoryA = categories[pointId][a];
    const categoryB = categories[pointId][b];
    if (categoryA.priority > categoryB.priority) {
      return -1;
    } else {
      return 1;
    }
  };

  const thisPointCategories = categories[pointId];
  const initialFormikState = {
    priority: 0,
    title: "",
  };

  const formikValidationSchema = Yup.object().shape({
    title: Yup.string()
      .required(REQUIRED_FIELD_ERROR)
      .min(3, "Название должно быть не менее 3 символов")
      .max(15, "Название должно быть не более 15 символов"),
    priority: Yup.number().required(REQUIRED_FIELD_ERROR),
  });

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={initialFormikState}
          validationSchema={formikValidationSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            values.point_id = pointId;
            const addCategory = dispatch(commonActions.addCategory(values));
            addCategory.then(
              (data) => {
                setSubmitting(true);
                cogoToast.success("Сохранено!");
                setIsLoadingQueryUpdate(false);
                setForce(force + 1);
              },
              ({ errors }) => {
                setSubmitting(false);
                cogoToast.error("Ошибка!");
              },
            );
          }}
          render={({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Spacer size={5} />
              <div className="SpecialCategories">
                <div className="SpecialCategories_inputBox">
                  <Input
                    maxlength={20}
                    name="title"
                    mode="secondary"
                    title="Название категории"
                    placeholder="Введите название категории"
                    value={values.title}
                    onChangeHandler={(e) => setFieldValue("title", e)}
                    error={!!(errors.title && touched.title)}
                    description={
                      errors.title &&
                      touched.title && <ErrorMessage name="title" component="div" className="invalid-feedback" />
                    }
                  />
                </div>
                <div className="SpecialCategories_inputBox">
                  <Input
                    maxlength={10}
                    name="priority"
                    mode="secondary"
                    title="Позиция в списке"
                    placeholder="Введите название товара"
                    value={values.priority}
                    onChangeHandler={(e) => setFieldValue("priority", e)}
                    error={!!(errors.priority && touched.priority)}
                    description={
                      errors.priority &&
                      touched.priority && <ErrorMessage name="priority" component="div" className="invalid-feedback" />
                    }
                  />
                </div>

                <div className="SpecialCategories_buttonBox">
                  <PrimaryButton text="Добавить" mode="primary" btnType="submit" />
                </div>
              </div>
            </Form>
          )}
        />
      </div>
    );
  };

  const renderItem = () => {
    return (
      <div>
        <Padding horizontal={2} vertical={2}>
          {[...draggingList].sort(sortable).map((item) => (
            <Cell
              key={uuidv4}
              draggable
              onDragFinish={({ from, to }) => {
                const copyDraggingList = [...draggingList];
                copyDraggingList.splice(from, 1);
                copyDraggingList.splice(to, 0, draggingList[from]);
                setDraggingList(copyDraggingList);
              }}>
              <div
                className="SpecialCategories_item-category"
                onClick={() => {
                  const categoryId = thisPointCategories[item].id;
                  Modals.open(
                    "editCategory",
                    { categoryId },
                    {},
                    {
                      onCloseHandler: () => {
                        updateSubjects();
                      },
                    },
                  );
                }}>
                <div className="SpecialCategories_item-category_left">
                  <span>{thisPointCategories[item].title}</span>
                </div>
                <div className="SpecialCategories_item-category_right">
                  <EditSVG />
                </div>
              </div>
            </Cell>
          ))}
        </Padding>
      </div>
    );
  };

  return (
    <div className="SpecialCategories">
      <Padding horizontal={3} vertical={3}>
        <Tumbler
          on={tumblerOn}
          onChange={() => setTumblerOn(!tumblerOn)}
          firstTitle="Категории"
          secondTitle="Добавить"
        />
      </Padding>
      {!tumblerOn && <div>{renderItem()}</div>}
      {!!tumblerOn && <div>{renderForm()}</div>}
    </div>
  );
};

export default SpecialCategories;

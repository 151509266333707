import "./index.scss";
import React, { useState } from "react";
import MobileHeader from "../../../components/ui/mobile/MobileHeader";
import router5 from "../../../router";
import { PAGE_ROUTES } from "../../../definitions";
import { ReactComponent as AddSVG } from "../../../static/images/svg/new/left_chevron.svg";
import HeaderTitle from "../../../components/ui/mobile/HeaderTitle";
import Padding from "../../../components/ui/universal/Padding";
import { ReactComponent as SearchSVG } from "../../../components/ui/mobile/HeaderIcon/assets/svg/search.svg";
import Input from "../../../components/ui/universal/Input";
import { ReactComponent as CategoriesSVG } from "../../../static/images/svg/new/categories.svg";
import Spacer from "../../../components/ui/universal/Spacer";

const MainSearchPage = () => {
  const [value, setValue] = useState("");

  const renderInfo = () => {
    return (
      <div className="MainSearchPage_info">
        <div className="MainSearchPage_info_box">
          <div className="MainSearchPage_info_box_icon">
            <SearchSVG />
          </div>
          <div className="MainSearchPage_info_box_title">
            <span>Введите запрос</span>
          </div>
          <div className="MainSearchPage_info_box_text">
            <span>Вы не ввели поисковой запрос</span>
          </div>
        </div>
      </div>
    );
  };

  const renderInfoError = () => {
    return (
      <div className="MainSearchPage_info">
        <div className="MainSearchPage_info_box">
          <div className="MainSearchPage_info_box_icon">
            <SearchSVG />
          </div>
          <div className="MainSearchPage_info_box_title">
            <span>Не найдено</span>
          </div>
          <div className="MainSearchPage_info_box_text">
            <span>К сожалению, мы не смогли найти информацию попо вашему запрос.</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="MainSearchPage">
      <MobileHeader
        left={
          <div
            className="CitiesPage_header_icon"
            onClick={() => {
              router5.navigate(PAGE_ROUTES.MAIN);
            }}>
            <AddSVG />
          </div>
        }
        right={<div />}>
        <HeaderTitle text="Поиск" align="center" />
      </MobileHeader>
      <div>
        <Padding horizontal={2} vertical={2}>
          <div>
            <Input
              icon={<SearchSVG />}
              mode="secondary"
              placeholder={"поиск"}
              value={value}
              onChangeHandler={setValue}
            />
          </div>

          <Spacer size={4} />

          <div className="MainSearchPage_result">
            <span>Результаты поиска</span>
            <div className="MainSearchPage_result_icon">
              <CategoriesSVG />
            </div>
          </div>
        </Padding>
      </div>
      {value === "" && (
        <div>
          <Padding vertical={2} horizontal={2}>
            {renderInfo()}
          </Padding>
        </div>
      )}

      {value === "1" && (
        <div>
          <Padding vertical={2} horizontal={2}>
            <div>Тут будет выведен товар но я пока думаю как это сделать (на проверку в коде не обращай внимания)</div>
          </Padding>
        </div>
      )}

      {value === "жопа" && (
        <div>
          <Padding vertical={2} horizontal={2}>
            {renderInfoError()}
          </Padding>
        </div>
      )}
    </div>
  );
};

export default MainSearchPage;

import "./index.scss";
//
import * as React from "react";
//
import classNames from "../../../utils/classNames";

class RowListItem extends React.Component {
  render() {
    const { props } = this;
    const cn = classNames({
      RowMainStyle: true,
      RowMainDisabled: props.disabled,
    });
    return (
      <>
        <div className={cn} onClick={!props.disabled ? props.onClick : () => {}}>
          {props.disabled && (
            <div className="RowMainDisabledText">
              <span>В разработке</span>
            </div>
          )}
          {props.icon && <div className="RowMainIcon">{props.icon}</div>}
          <div className="RowMainTitle">{props.title}</div>
          <div className="chevron">{props.chevron}</div>
        </div>
      </>
    );
  }
}

RowListItem.defaultProps = {
  title: "",
  icon: undefined,
  chevron: undefined,
  onClick: () => {},
  disabled: false,
};

export default RowListItem;

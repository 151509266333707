import "./index.scss";
//
import React, { FC, useState, useEffect } from "react";
//
import { IPropsComponent, IMoreButtonProps } from "./assets/interfaces";
import Loading from "../../ui/universal/Loading";

const Pagination: FC<IPropsComponent> = (props) => {
  const { children, loadingMore, loadingStatus, hasNext } = props;

  const [state, setState] = useState({
    locked: false,
  });

  const setLockedStatus = (status: boolean = true) => {
    setState({
      ...state,

      locked: status,
    });
  };

  const setLocked = () => {
    setLockedStatus();

    setTimeout(() => {
      setLockedStatus(false);
    }, 2000);
  };

  const onScrollHandler = () => {
    if (hasNext) {
      const [bottomPoint, scrollHeight] = [window.scrollY + window.innerHeight, document.body.scrollHeight];

      const needPercent = (scrollHeight / 100) * 80;

      if (bottomPoint > needPercent) {
        if (!state.locked) {
          loadingMore();
          setLocked();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScrollHandler);

    return () => {
      window.removeEventListener("scroll", onScrollHandler);
    };
  });

  return (
    <>
      {children}
      {hasNext && <MoreButton loadingStatus={loadingStatus} onClick={loadingMore} />}
    </>
  );
};

Pagination.defaultProps = {
  loadingMore: () => {},
  loadingStatus: true,
};

const MoreButton: FC<IMoreButtonProps> = ({ loadingStatus, onClick }) => {
  return (
    <div onClick={onClick}>
      <div className="pagination__more-button">
        {loadingStatus ? (
          <span>
            <Loading />
          </span>
        ) : (
          <span>Больше...</span>
        )}
      </div>
    </div>
  );
};

MoreButton.defaultProps = {
  onClick: () => {},
};

export default Pagination;

import React  from 'react';
import { APIProvider } from './APIProvider';
import { NEW_API_ENTRYPOINT, NEW_API_VERSION } from '../../config';
import { AuthContextProvider, useAuthAccessToken } from './AuthContext';
import { LoggedContextProvider } from './LoggedContext';

const APIWrapper = ({children}) => {
    const accessToken = useAuthAccessToken();
    return (
      <APIProvider
        serviceParams={{
            v: NEW_API_VERSION,
            access_token: accessToken,
        }}
        endpoint={NEW_API_ENTRYPOINT}
      >
          {children}
      </APIProvider>
    );
}

export const APIAuthProvider = ({children}) => {
    return (
      <AuthContextProvider>
          <APIWrapper>
            <LoggedContextProvider>
              {children}
            </LoggedContextProvider>
          </APIWrapper>
      </AuthContextProvider>
    );
};

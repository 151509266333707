import "./index.scss";
import React, { useState } from "react";
import Padding from "../../../components/ui/universal/Padding";
import Input from "../../../components/ui/universal/Input";
import Spacer from "../../../components/ui/universal/Spacer";
import PrimaryButton from "../../../components/ui/universal/PrimaryButton";
import Textarea from "../../../components/ui/universal/Textarea";

const ForDevPage = () => {
  const [value, setValue] = useState("");

  return (
    <div>
      <Padding horizontal={2} vertical={2}>
        <Spacer size={10} />

        <Input title={"Введите тему запроса"} mode="secondary" value={value} onChangeHandler={setValue} />

        <Spacer size={10} />

        <Input title={"Введите ваш Email"} mode="secondary" value={value} onChangeHandler={setValue} />

        <Spacer size={10} />

        <Textarea
          title="Опишите ваш запрос"
          maxRows={5}
          minRows={5}
          value={value}
          placeholder="Введите краткое описание"
          onChangeHandler={setValue}
        />

        <Spacer size={15} />
        <PrimaryButton mode="primary" text="Отправить" />
      </Padding>
    </div>
  );
};

export default ForDevPage;
